import styled from "styled-components";

export const Container = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 999999999;
  display: flex;
  width: 100vw;
  height: 70px;
  margin: 0;
  padding: 0;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  transition: opacity 1s ease, visibility 0s;

  &.off {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 2s;
  }

  // @media only screen and (max-width: 600px) {
  //   bottom: 0px;
  // }
`;
