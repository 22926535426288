import { Arrow, ArrowUp } from "../../assets/images";
const productsEscritorio = [
  {
    id: 0,
    name: "Subir",
    environmentArea: 0,
    category: { image: ArrowUp },
    pinPos: [310.16465383582454, 223.6973661058909, -0.3737567313290532],
    boardPos: [0, 0, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 377.2564965894427,
        y: 853.9503735670879,
        z: -1525.1264231712566,
      },
      target: {
        x: 377.0916363906531,
        y: 853.8582495651767,
        z: -1524.1444179354626,
      },
      posMobile: {
        x: 377.2564965894427,
        y: 853.9503735670879,
        z: -1525.1264231712566,
      },
      targetMobile: {
        x: 377.0916363906531,
        y: 853.8582495651767,
        z: -1524.1444179354626,
      },
    },
    cameraOut: {
      pos: {
        x: 314.98858959613983,
        y: 650.1742480495607,
        z: -69.58620861537187,
      },
      target: {
        x: 294.32783210205434,
        y: 638.5266933326376,
        z: -103.97560125467244,
      },
    },
  },
  {
    id: -1,
    name: "Descer",
    environmentArea: 0,
    category: { image: Arrow },
    pinPos: [438.160685326184, 832.6774541946927, -1287.7609817284706],
    boardPos: [0, 0, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 73.65370867037373,
        y: 230.7714702122137,
        z: 174.93367314212009,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
      posMobile: {
        x: 73.65370867037373,
        y: 230.7714702122137,
        z: 174.93367314212009,
      },
      targetMobile: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
    cameraOut: {
      pos: {
        x: 84.50369154431179,
        y: 230.68183835456134,
        z: 214.4330955821151,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 3,
    name: "Placa Gesso Performa Placo",
    environmentArea: 0,
    pinPos: [0, 791.5924801998526, -1617.787666203872],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 115.30145895433657,
        y: 792.7123208604828,
        z: -1343.8878476083398,
      },
      target: {
        x: 114.76080468822694,
        y: 792.6524462898406,
        z: -1344.726959043328,
      },
      posMobile: {
        x: 115.30145895433657,
        y: 792.7123208604828,
        z: -1343.8878476083398,
      },
      targetMobile: {
        x: 114.76080468822694,
        y: 792.6524462898406,
        z: -1344.726959043328,
      },
    },
    cameraOut: {
      pos: {
        x: 373.7296655178479,
        y: 852.9872918337824,
        z: -1520.8812310599478,
      },
      target: {
        x: 372.770105523286,
        y: 852.8198681135162,
        z: -1521.1075359753356,
      },
    },
  },
  {
    id: 4,
    name: "Placa Placa Gesso LEV Placo",
    pinPos: [-65.69068293368271, 553.693585841839, -567.2657868186132],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -117.62112258345059,
        y: 471.6095705698437,
        z: -219.97815034174755,
      },
      target: {
        x: -117.65557474622132,
        y: 471.8238092558142,
        z: -220.9543239092621,
      },
      posMobile: {
        x: -117.62112258345059,
        y: 471.6095705698437,
        z: -219.97815034174755,
      },
      targetMobile: {
        x: -117.65557474622132,
        y: 471.8238092558142,
        z: -220.9543239092621,
      },
    },
    cameraOut: {
      pos: {
        x: 74.19211063520716,
        y: 230.25298961024086,
        z: 174.8835243804577,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 6,
    name: "supergraute quartzolit",
    pinPos: [-117.76311122561943, 255.8676671430385, 422.69307427362554],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 148.08553540686546,
        y: 199.98969672556476,
        z: 167.35318287628002,
      },
      target: {
        x: 147.51251593868082,
        y: 200.14765072998316,
        z: 168.1573589898084,
      },
      posMobile: {
        x: 148.08553540686546,
        y: 199.98969672556476,
        z: 167.35318287628002,
      },
      targetMobile: {
        x: 147.51251593868082,
        y: 200.14765072998316,
        z: 168.1573589898084,
      },
    },
    cameraOut: {
      pos: {
        x: 74.58694760284965,
        y: 230.6345272940975,
        z: 173.19166233556032,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 7,
    name: "reparo estrutural quartzolit",
    pinPos: [-166.315553047148, 133.14237529191533, 265.5364773465607],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 148.37362712847295,
        y: 200.35861329385688,
        z: 167.69564607230254,
      },
      target: {
        x: 147.51202024570478,
        y: 200.14785660992487,
        z: 168.15739838658422,
      },
      posMobile: {
        x: 148.37362712847295,
        y: 200.35861329385688,
        z: 167.69564607230254,
      },
      targetMobile: {
        x: 147.51202024570478,
        y: 200.14785660992487,
        z: 168.15739838658422,
      },
    },
    cameraOut: {
      pos: {
        x: 74.86284352942002,
        y: 231.08697515689767,
        z: 173.69999011702112,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 19,
    name: "Painel Wallfelt Isover",
    environmentArea: 0,
    pinPos: [154.39834582632312, 791.9881293696035, -1616.6515165861051],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 117.91798064475329,
        y: 799.9289152867865,
        z: -1380.816945685375,
      },
      target: {
        x: 117.9058823777472,
        y: 799.8966504336602,
        z: -1381.8163518146278,
      },
      posMobile: {
        x: 117.91798064475329,
        y: 799.9289152867865,
        z: -1380.816945685375,
      },
      targetMobile: {
        x: 117.9058823777472,
        y: 799.8966504336602,
        z: -1381.8163518146278,
      },
    },
    cameraOut: {
      pos: {
        x: 373.6447804400869,
        y: 853.0626211209316,
        z: -1520.6879948363396,
      },
      target: {
        x: 372.770105523286,
        y: 852.8198681135162,
        z: -1521.1075359753356,
      },
    },
  },
  {
    id: 24,
    name: "Sistema Nextera com Placa Cimentícia Brasilit",
    pinPos: [545.8361019047832, 507.3799889151331, -167.74853469446668],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 259.9165443655119,
        y: 619.6283136520755,
        z: 19.353536935577495,
      },
      target: {
        x: 260.60133154161804,
        y: 619.2842488328096,
        z: 18.711130581636194,
      },
      posMobile: {
        x: 259.9165443655119,
        y: 619.6283136520755,
        z: 19.353536935577495,
      },
      targetMobile: {
        x: 260.60133154161804,
        y: 619.2842488328096,
        z: 18.711130581636194,
      },
    },
    cameraOut: {
      pos: {
        x: 73.29424503909073,
        y: 230.25098946645082,
        z: 174.56366562800395,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 29,
    name: "Nexalux Vert 90/65 Sonex",
    pinPos: [290.98039527125286, 436.78102562378433, -481.6833937166695],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 134.60421865351174,
        y: 391.8955848660139,
        z: -202.908169979825,
      },
      target: {
        x: 134.95094742416703,
        y: 392.0392712537609,
        z: -203.83506446226414,
      },
      posMobile: {
        x: 134.60421865351174,
        y: 391.8955848660139,
        z: -202.908169979825,
      },
      targetMobile: {
        x: 134.95094742416703,
        y: 392.0392712537609,
        z: -203.83506446226414,
      },
    },
    cameraOut: {
      pos: {
        x: 73.69780322772573,
        y: 230.37225763984983,
        z: 174.90214778731848,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 30,
    name: "Forro Ecophon Gedina A Ecophon",
    environmentArea: 0,
    pinPos: [390.0618319579788, 1142.433008297714, -1822.8822323487834],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 494.64117628095266,
        y: 980.7656243375316,
        z: -1687.8015472060467,
      },
      target: {
        x: 494.07380900839314,
        y: 981.4685971238275,
        z: -1688.230410400732,
      },
      posMobile: {
        x: 494.64117628095266,
        y: 980.7656243375316,
        z: -1687.8015472060467,
      },
      targetMobile: {
        x: 494.07380900839314,
        y: 981.4685971238275,
        z: -1688.230410400732,
      },
    },
    cameraOut: {
      pos: {
        x: 372.7532132508266,
        y: 852.1252785492292,
        z: -1520.3883280858386,
      },
      target: {
        x: 372.770105523286,
        y: 852.8198681135162,
        z: -1521.1075359753356,
      },
    },
  },
  {
    id: 35,
    name: "Nuvens Illtec Sonex",
    environmentArea: 0,
    pinPos: [91.7630318608845, 1063.0335548541616, -1030.8888605935638],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 114.54248388837986,
        y: 792.0228534349642,
        z: -1345.446210246037,
      },
      target: {
        x: 114.66424804924218,
        y: 792.6299294107147,
        z: -1344.6609508947422,
      },
      posMobile: {
        x: 114.54248388837986,
        y: 792.0228534349642,
        z: -1345.446210246037,
      },
      targetMobile: {
        x: 114.66424804924218,
        y: 792.6299294107147,
        z: -1344.6609508947422,
      },
    },
    cameraOut: {
      pos: {
        x: 377.4034600998819,
        y: 853.2612546063929,
        z: -1524.3444888830231,
      },
      target: {
        x: 376.97978422002706,
        y: 853.6282811275032,
        z: -1523.5163643261894,
      },
    },
  },
  {
    id: 39,
    name: "impermeabilizante tecplus top quartzolit",
    pinPos: [77.23288320112366, 34.9617446088457, -379.4427429434977],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 184.17181924504678,
        y: 132.20555971413324,
        z: -143.42412048883767,
      },
      target: {
        x: 183.63209926398955,
        y: 131.87572425998547,
        z: -144.1986596519758,
      },
      posMobile: {
        x: 184.17181924504678,
        y: 132.20555971413324,
        z: -143.42412048883767,
      },
      targetMobile: {
        x: 183.63209926398955,
        y: 131.87572425998547,
        z: -144.1986596519758,
      },
    },
    cameraOut: {
      pos: {
        x: 74.0707409196673,
        y: 231.00880934201632,
        z: 174.94180047030858,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 46,
    name: "PU 144 quartzolit",
    pinPos: [-58.78186326919328, 19.283331229836115, 418.420796927859],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 147.91909828967678,
        y: 200.64813725388694,
        z: 167.39265962836438,
      },
      target: {
        x: 147.51359973828718,
        y: 200.14720058723248,
        z: 168.15727285138766,
      },
      posMobile: {
        x: 147.91909828967678,
        y: 200.64813725388694,
        z: 167.39265962836438,
      },
      targetMobile: {
        x: 147.51359973828718,
        y: 200.14720058723248,
        z: 168.15727285138766,
      },
    },
    cameraOut: {
      pos: {
        x: 74.36266720035067,
        y: 231.28516952083496,
        z: 173.2911691850469,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
];

export default productsEscritorio;
