import React, { useRef, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import * as THREE from "three";
import { useLoader, useThree } from "@react-three/fiber";
import {
  Circle,
  Backdrop,
  Billboard,
  Image,
  Text,
  Html,
  PivotControls,
} from "@react-three/drei";
import plusTexture from "../../../../assets/images/plus.png";
import nd from "../../../../assets/images/categorias/nd.png";
import { TextContainer, Title, Description } from "./styled.js";
import { forwardRef } from "react";

const PinThumb = forwardRef(
  (
    {
      url,
      index,
      locationProps,
      setLocation,
      location,
      isInsideSection,
      setIsInsideSection,
      setIsAnimating,
      setData,
      isHover,
      setIsHover,
      local,
      refOrbit,
      setEnvironmentArea,
      navigate,
    },
    ref
  ) => {
    console.log("url", url);
    // const plusMap = useLoader(THREE.TextureLoader, plusTexture);
    const texture = useLoader(THREE.TextureLoader, url ? url : nd);
    texture.needsUpdate = true;
    texture.minFilter = THREE.LinearFilter;
    const hover = (e, index) => {
      e.stopPropagation();
      setLocation(index);
      setIsHover(true);
    };

    const outer = (e, index) => {
      e.stopPropagation();
      setIsHover(false);
    };

    function retira_acentos(str) {
      const com_acento =
        "ÀÁÂÃÄÅÆÇÈÉÊËÌÍÎÏÐÑÒÓÔÕÖØÙÚÛÜÝŔÞßàáâãäåæçèéêëìíîïðñòóôõöøùúûüýþÿŕ";

      const sem_acento =
        "AAAAAAACEEEEIIIIDNOOOOOOUUUUYRsBaaaaaaaceeeeiiiionoooooouuuuybyr";
      let novastr = "";
      for (let i = 0; i < str.length; i++) {
        let troca = false;
        for (let a = 0; a < com_acento.length; a++) {
          if (str.substr(i, 1) == com_acento.substr(a, 1)) {
            novastr += sem_acento.substr(a, 1);
            troca = true;
            break;
          }
        }
        if (troca == false) {
          novastr += str.substr(i, 1);
        }
      }
      return novastr;
    }

    const clicked = (e, index) => {
      // console.log("clicked", local);
      // console.log(locationProps[index]);
      e.stopPropagation();
      navigate(
        `/in/${local}/${retira_acentos(
          locationProps[index].name
            .toLowerCase()
            .replace(/ /g, "")
            .replaceAll("/", "")
            .trim()
        )}`
      );
    };

    return (
      <>
        <Circle
          args={[20, 40]}
          scale={locationProps[index].scale ? locationProps[index].scale : 1}
        >
          <meshBasicMaterial color={"#ffffff"} transparent />
        </Circle>
        {!isMobile ? (
          <Circle
            ref={ref}
            args={[20, 40]}
            scale={locationProps[index].scale ? locationProps[index].scale : 1}
            position={[0, 0, 0.2]}
            onPointerEnter={(e) => {
              !isInsideSection && hover(e, index);
            }}
            onPointerLeave={(e) => {
              !isInsideSection && outer(e, index);
            }}
            onClick={(e) => {
              outer(e, index);
              !isInsideSection && clicked(e, index);
            }}
            style={{ cursor: "pointer" }}
          >
            <meshBasicMaterial map={texture} transparent />
          </Circle>
        ) : (
          <Circle
            ref={ref}
            args={[20, 40]}
            scale={locationProps[index].scale ? locationProps[index].scale : 1}
            position={[0, 0, 0.2]}
            onClick={(e) => {
              outer(e, index);
              !isInsideSection && clicked(e, index);
            }}
            style={{ cursor: "pointer" }}
          >
            <meshBasicMaterial map={texture} transparent />
          </Circle>
        )}
      </>
    );
  }
);

const BillboardMenu = ({
  enterProduct,
  follow,
  lockX,
  lockY,
  lockZ,
  location,
  local,
  setLocation,
  isHover,
  setIsHover,
  setIsAnimating,
  setData,
  locationProps,
  refOrbit,
  locationData,
  isInsideSection,
  setIsInsideSection,
  environmentArea,
  setEnvironmentArea,
  navigate,
}) => {
  const { camera } = useThree();
  const plusMap = useLoader(THREE.TextureLoader, plusTexture);
  const ref = useRef();
  const pivotRef = useRef(null);
  const [turnOnPivot, setTurnOnPivot] = useState(false);
  const clone = require("rfdc")();

  const getPivotPosition = (matrix, pinPos) => {
    let newValue = new THREE.Vector3()
      .setFromMatrixPosition(matrix)
      .add(new THREE.Vector3(pinPos[0], pinPos[1], pinPos[2]));
    console.log("pivot position", [newValue.x, newValue.y, newValue.z]);
  };

  useEffect(() => {
    // console.log(locationProps);
  }, [locationProps]);

  return (
    <>
      {locationProps.map((locationProp, index) => {
        // console.log(locationProp);
        return turnOnPivot ? (
          <PivotControls
            key={locationProp.name + index}
            anchor={[0, 0, 0]}
            scale={75}
            depthTest={false}
            fixed
            lineWidth={2}
            onDrag={(matrix) => getPivotPosition(matrix, locationProp.pinPos)}
          >
            <Billboard
              ref={pivotRef}
              follow={follow}
              lockX={lockX}
              lockY={lockY}
              lockZ={lockZ}
              position={locationProp.pinPos}
            >
              <PinThumb
                url={
                  locationProp.category && locationProp.category.image
                    ? locationProp.category.image
                    : ""
                }
                index={index}
                locationProps={locationProps}
                location={location}
                setLocation={setLocation}
                isInsideSection={isInsideSection}
                setIsInsideSection={setIsInsideSection}
                setIsAnimating={setIsAnimating}
                setData={setData}
                isHover={isHover}
                setIsHover={setIsHover}
                local={local}
                refOrbit={refOrbit}
                setEnvironmentArea={setEnvironmentArea}
                navigate={navigate}
              />
              {location === index &&
                isHover &&
                locationProp.category !== null &&
                locationProp.name !== "Subir" &&
                locationProp.name !== "Descer" &&
                locationProp.name !== "Sair" &&
                locationProp.name !== "Entrar" && (
                  <Html
                    ref={ref}
                    style={{
                      userSelect: "none",
                      pointerEvents: "none",
                      backgroundColor: "white",
                      borderRadius: "10px",
                      zIndex: "0",
                      transform: "translateX(-50%) translateY(calc(-120%))",
                    }}
                    // position={locationProp.boardPos}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexFlow: "column wrap",
                        userSelect: "none",
                        width: "176px",
                        height: "87px",
                        borderTopLeftRadius: "10px",
                        borderTopRightRadius: "10px",
                        overflow: "hidden",
                        zIndex: "0",
                        backgroundColor: "#ffffff",
                      }}
                    >
                      <img
                        src={locationProp.image}
                        height="100%"
                        width="auto"
                        style={{
                          pointerEvents: "none",
                          objectFit: "contain",
                        }}
                      />
                    </div>
                    <TextContainer>
                      <Title>{locationProp.category.name}</Title>
                      <Description>{locationProp.name}</Description>
                    </TextContainer>
                  </Html>
                )}
            </Billboard>
          </PivotControls>
        ) : (
          <React.Fragment key={locationProp.name + index}>
            {locationProp.environmentArea === environmentArea && (
              <Billboard
                ref={pivotRef}
                key={locationProp.name}
                follow={follow}
                lockX={lockX}
                lockY={lockY}
                lockZ={lockZ}
                position={locationProp.pinPos}
              >
                <PinThumb
                  url={
                    locationProp.category && locationProp.category.image
                      ? locationProp.category.image
                      : ""
                  }
                  index={index}
                  locationProps={locationProps}
                  setLocation={setLocation}
                  isInsideSection={isInsideSection}
                  setIsInsideSection={setIsInsideSection}
                  setIsAnimating={setIsAnimating}
                  setData={setData}
                  isHover={isHover}
                  setIsHover={setIsHover}
                  local={local}
                  refOrbit={refOrbit}
                  setEnvironmentArea={setEnvironmentArea}
                  navigate={navigate}
                />
                {location === index &&
                  isHover &&
                  locationProp.category !== null &&
                  locationProp.name !== "Subir" &&
                  locationProp.name !== "Descer" &&
                  locationProp.name !== "Sair" &&
                  locationProp.name !== "Entrar" && (
                    <Html
                      ref={ref}
                      style={{
                        userSelect: "none",
                        pointerEvents: "none",
                        backgroundColor: "white",
                        borderRadius: "10px",
                        zIndex: "0",
                        transform: "translateX(-50%) translateY(calc(-120%))",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexFlow: "column wrap",
                          userSelect: "none",
                          width: "176px",
                          height: "87px",
                          borderTopLeftRadius: "10px",
                          borderTopRightRadius: "10px",
                          overflow: "hidden",
                          zIndex: "0",
                          backgroundColor: "#ffffff",
                        }}
                      >
                        <img
                          src={locationProp.image}
                          height="100%"
                          width="auto"
                          style={{
                            pointerEvents: "none",
                            objectFit: "contain",
                          }}
                        />
                      </div>
                      <TextContainer>
                        <Title>
                          {locationProp.category.name.toLowerCase()}
                        </Title>
                        <Description>{locationProp.name}</Description>
                      </TextContainer>
                    </Html>
                  )}
              </Billboard>
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};

export default BillboardMenu;
