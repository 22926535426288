import { useEffect, useState } from "react";
import "./style.scss";
import { usePreload } from "../../../contexts/preload";

const Preloader = () => {
  const { preloading, setPreloading } = usePreload();
  const [classname, setClassname] = useState("");

  useEffect(() => {
    if (preloading) {
      setClassname("on");
    } else {
      setClassname("");
    }
  }, [preloading]);

  return (
    <div id="wrapper" className={classname}>
      <div className="profile-main-loader">
        <div className="loader">
          <svg className="circular-loader" viewBox="25 25 50 50">
            <circle
              className="loader-path"
              cx="50"
              cy="50"
              r="20"
              fill="none"
              stroke="#70c542"
              strokeWidth="2"
            />
          </svg>
        </div>
      </div>
    </div>
  );
};

export default Preloader;
