import styled from "styled-components";

export const Container = styled.div`
  position: absolute;
  bottom: 50px;
  right: 50px;

  img {
    margin-left: 25px;
  }
`;
