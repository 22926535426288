import styled from "styled-components";
import logo from "../../../../assets/images/logo-cidade-inteligente.png";
import { ArrowMini, SecondaryButton } from "../../../../assets/images";
import bgMenu from "../../../../assets/images/bg-menu.png";

export const Container = styled.div`
  width: 194px;
  height: 100vh;
  pointer-events: none;
  position: absolute;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-flow: column wrap;
  box-sizing: border-box;
  left: 3%;
  opacity: 0;
  visibility: hidden;
  transition: opacity 1.5s ease, visibility 2s;

  &.on {
    transition: opacity 1.5s ease, visibility 0s;
    visibility: visible;
    opacity: 1;
  }

  @media only screen and (min-width: 601px) and (min-height: 300px) {
    width: 140px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    width: 140px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    width: 140px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    width: 194px;
  }

  &.mobile {
    position: absolute;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center;
    left: 0%;
    opacity: 0;
    visibility: hidden;
    transition: opacity 1.5s ease, visibility 2s;

    &.on {
      transition: opacity 1.5s ease, visibility 0s;
      visibility: visible;
      opacity: 1;
    }
  }
`;

export const Logo = styled.div`
  width: auto;
  height: 80px;
  background-color: #fff9f4;
  border-radius: 8px;
  margin-bottom: 25px;
  background-image: url(${logo});
  background-repeat: no-repeat;
  background-position: 15% center;
`;

export const SecondaryMenuButton = styled.div`
  position: fixed;
  width: 40px;
  height: 40px;
  left: 30px;
  bottom: 30px;
  background-image: url(${SecondaryButton});
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
`;

export const MenuContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  max-width: 194px;
  // max-height: 60vh;
  border-radius: 8px;
  box-size: border-box;
  padding: 0px;
  overflow-x: hidden;
  overflow-y: auto;
  pointer-events: auto;
  margin-top: 330px;

  &::-webkit-scrollbar {
    display: none;
  }

  @media only screen and (max-width: 600px) {
    max-width: 100%;
    width: 70%;
    margin-top: 260px;
  }
  @media only screen and (min-width: 601px) and (min-height: 300px) {
    max-width: 140px;
    width: 100%;
    margin-top: 250px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    max-width: 140px;
    width: 100%;
    margin-top: 250px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    max-width: 140px;
    width: 100%;
    margin-top: 250px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    max-width: 194px;
    width: 100%;
    margin-top: 330px;
  }

  &.mobile {
    max-width: 200px;
    width: 50%;
    margin-top: 30px;
  }
`;

export const MenuTop = styled.div`
  width: 100%;
  height: 37px;
  overflow: hidden;
  background-image: url(${bgMenu});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;

  @media only screen and (min-width: 601px) and (min-height: 300px) {
    height: 25px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    height: 25px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    height: 25px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    height: 37px;
  }
  &.mobile {
    height: 37px;
  }
`;
export const MenuBottom = styled.div`
  width: 100%;
  height: 37px;
  background-image: url(${bgMenu});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center bottom;
  @media only screen and (min-width: 601px) and (min-height: 300px) {
    height: 25px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    height: 25px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    height: 25px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    height: 37px;
  }
  &.mobile {
    height: 37px;
  }
`;

export const Menu = styled.div`
  width: 100%;
  margin-left: 1px;
  box-sizing: border-box;
  background-color: rgba(255, 255, 255, 0.9);

  &::-webkit-scrollbar {
    display: none;
  }

  &.mobile {
    // visibility: hidden;
    // opacity:0;
  }
`;

export const TabMenu = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  top: 280px;
  left: 50%;
  transform: translateX(-50%);
  background-color: ${({ theme }) => theme.colors.yellow};
  border-radius: 20px;
  padding: 3px;
  box-size: border-box;
  @media only screen and (max-width: 600px) {
    width: 70%;
    top: 210px;
  }
  @media only screen and (min-width: 601px) and (min-height: 300px) {
    top: 200px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    top: 200px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    top: 200px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    top: 280px;
  }
  &.mobile {
    position: relative;
    max-width: 200px;
    width: 50%;
    top: 30px;
    left: unset;
    transform: unset;
    margin-bottom: 10px;
  }
`;

export const TabMenuItem = styled.div`
  curson: pointer;
  pointer-events: all;
  width: 50%;
  height: 30px;
  background-color: transparent;
  border-radius: 15px;
  text-align: center;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 12px;
  font-weight: 700;
  line-height: 30px;
  text-transform: capitalize;
  color: ${({ theme }) => theme.colors.blue};
  user-select: none;
  cursor: pointer;

  &::first-line {
    text-transform: capitalize;
  }

  @media only screen and (min-width: 601px) and (min-height: 300px) {
    font-size: 10px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    font-size: 10px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    font-size: 10px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    font-size: 12px;
  }

  &.selected {
    background-color: ${({ theme }) => theme.colors.blue};
    color: #ffffff;
  }
`;

export const Tab = styled.div`
  display: flex;
  flex-flow: column wrap;
  flex-shrink: 0;
  width: 100%;
  &.mobile {
    flex-flow: row wrap;
    max-height: 20vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export const TabItem = styled.div`
  width: auto;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;
  pointer-events: all;
  user-select: none;
  ${({ theme }) => theme.fonts.body};
  font-size: 12px;
  font-weight: 700;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.black};
  text-transform: capitalize;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 3px;
  padding-top: 3px;
  margin-bottom: 3px;
  margin-top: 3px;
  border-radius: 15px;
  user-select: none;

  &.bold {
    ${({ theme }) => theme.fonts.bodytitle} !important;
    font-size: 12px;
    line-height: 14px;
    font-weight: 900;
  }

  &::first-line {
    text-transform: capitalize;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 10px;
    line-height: 12px;
  }
  @media only screen and (min-width: 601px) and (min-height: 300px) {
    font-size: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    line-height: 9px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    font-size: 10px;
    padding-bottom: 0px;
    padding-top: 0px;
    line-height: 10px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    font-size: 11px;
    padding-bottom: 0px;
    padding-top: 0px;
    line-height: 11px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    font-size: 12px;
    padding-bottom: 3px;
    padding-top: 3px;
    line-height: 14px;
  }

  &.mobile {
    width: 100%;
  }

  &.selected {
    background-color: rgba(255, 255, 255, 0.6);
    font-weight: 700;
    // margin-left: -16px;
    // &:before {
    //   content: "> ";
    // }
  }
`;

export const TabItemSolucoes = styled.div`
  width: auto;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: column nowrap;
  flex-grow: 0;
  flex-shrink: 0;
  align-self: flex-start;
  pointer-events: all;
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  color: #24304f;
  text-transform: capitalize;
  padding-left: 0px;
  padding-right: 20px;
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 6px;
  margin-top: 6px;
  border-radius: 15px;
  user-select: none;

  &::first-line {
    text-transform: capitalize;
  }

  @media only screen and (max-width: 600px) {
    width: 100%;
    font-size: 10px;
    line-height: 12px;
    padding-right: 0px;
  }
  @media only screen and (min-width: 601px) and (min-height: 300px) {
    font-size: 10px;
    line-height: 9px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    font-size: 10px;
    line-height: 9px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    font-size: 11px;
    line-height: 11px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    font-size: 12px;
    line-height: 13px;
  }

  &.mobile {
    width: 100%;
  }

  &.selected {
    background-color: rgba(255, 255, 255, 0.6);
    font-weight: 700;
    // margin-left: -16px;
    // &:before {
    //   content: "> ";
    // }
  }
`;

export const TitSolucoes = styled.div`
  border-radius: 15px;
  text-transform: capitalize;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-top: 3px;
  box-sizing: border-box;

  @media only screen and (max-width: 600px) {
    margin-left: 0px;
  }

  @media only screen and (min-width: 601px) and (min-height: 300px) {
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    margin-top: 0px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    margin-top: 3px;
    padding-bottom: 0px;
    padding-top: 0px;
  }

  &.selected {
    background-color: rgba(255, 255, 255, 0.6);
    font-weight: 700;
    // padding-left: 20px;
    // &:before {
    //   content: "> ";
    // }
  }
`;

export const TabItemArrow = styled.div`
  position: absolute;
  width: 8px;
  height: 16px;
  left: 0px;
  top: 8px;
  // background-image: url(${ArrowMini});
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0;
  transition: all 0.3s ease;
  transform: rotate(0deg);

  &.hovered {
    left: 15px;
    opacity: 1;

    &.accordion {
      transform: rotate(90deg);
    }
  }
`;

export const TabSubitems = styled.div`
  display: flex;
  flex-flow: column wrap;
`;

export const TabSubitem = styled.div`
  width: auto;
  cursor: pointer;
  position: relative;
  display: flex;
  flex-flow: row nowrap;
  flex-grow: 0;
  align-self: flex-start;
  pointer-events: all;
  user-select: none;
  font-family: ${({ theme }) => theme.fonts.body};
  font-size: 12px;
  font-weight: 700;
  line-height: 13px;
  color: #505d80;
  text-transform: capitalize;
  padding-left: 60px;
  padding-right: 20px;
  padding-bottom: 2px;
  padding-top: 2px;
  margin-bottom: 0px;
  margin-top: 3px;
  border-radius: 15px;
  user-select: nono;

  &::first-line {
    text-transform: capitalize;
  }

  @media only screen and (max-width: 600px) {
    margin-left: -30px;
    width: 100%;
    font-size: 10px;
    line-height: 12px;
    padding-right: 0px;
  }
  @media only screen and (min-width: 601px) and (min-height: 300px) {
    font-size: 10px;
    line-height: 9px;
    padding-left: 30px;
    line-height: 10px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    font-size: 10px;
    line-height: 9px;
    padding-left: 30px;
    line-height: 10px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    font-size: 10px;
    line-height: 9px;
    padding-left: 30px;
    line-height: 10px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    font-size: 12px;
    line-height: 9px;
    padding-left: 60px;
    line-height: 13px;
  }

  &.selected {
    // background-color: rgba(255, 255, 255, 0.6);
    color: #24304f;
    font-weight: 700;
    text-indent: 10px;
    &:before {
      color: #24304f;
      content: "• ";
      margin-left: -27px;
      font-size: 18px;
      line-height: 9px;
    }
  }
`;
