import "./style.scss";
import { Html, useProgress } from "@react-three/drei";

const PreloaderCanvas = () => {
  // const { active, progress, errors, item, loaded, total } = useProgress();
  return (
    <Html
      style={{
        display: "flex",
        width: "100vw",
        height: "100vh",
        alignContent: "center",
        alignItems: "center",
        backgroundColor: "transparent",
        textAlign: "center",
      }}
      fullscreen={true}
    >
      <div id="wrapper" className={"on"}>
        <div className="profile-main-loader">
          <div className="loader">
            <svg className="circular-loader" viewBox="25 25 50 50">
              <circle
                className="loader-path"
                cx="50"
                cy="50"
                r="20"
                fill="none"
                stroke="#ffffff"
                strokeWidth="2"
              />
            </svg>
          </div>
        </div>
      </div>
    </Html>
  );
};

export default PreloaderCanvas;
