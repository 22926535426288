const productsParqueEolico = [
  {
    id: 16,
    name: "graute 100 quartzolit",
    pinPos: [-1119.5541386623709, 115.602576175469, -376.31435385767065],
    scale: 2.5,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 74.96313848686815,
        y: 230.74088980476034,
        z: 174.26206232489616,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
      posMobile: {
        x: 74.96313848686815,
        y: 230.74088980476034,
        z: 174.26206232489616,
      },
      targetMobile: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
    cameraOut: {
      pos: {
        x: 74.89913744980059,
        y: 230.77160677525077,
        z: 174.42800713892217,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 52,
    name: "Graute 100",
    pinPos: [-1179.741435095507, 113.23521767618517, 1475.9442608870386],
    scale: 2.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 74.56515671938158,
        y: 230.73989444564828,
        z: 173.17718155934435,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
      posMobile: {
        x: 74.56515671938158,
        y: 230.73989444564828,
        z: 173.17718155934435,
      },
      targetMobile: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
    cameraOut: {
      pos: {
        x: 74.7198324203988,
        y: 230.72264212649063,
        z: 173.3071530352495,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
];

export default productsParqueEolico;
