/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef, useEffect } from "react";
import { invalidate, useFrame, useThree } from "@react-three/fiber";
import { useGLTF, useAnimations, useTexture, Circle } from "@react-three/drei";
import * as THREE from "three";
import model from "./Cidade_V10.glb";
import mapImg from "../../images/maps.png";

export default function Cidade({
  scale,
  placeHighlighted,
  setPlaceHighlighted,
  setIsAnimating,
  setData,
  location,
  setLocation,
  locationProps,
  refOrbit,
  secondaryMenuOn,
  setSecondaryMenuOn,
}) {
  const group = useRef();
  const { nodes, materials, animations } = useGLTF(model);
  const { actions } = useAnimations(animations, group);
  const { camera } = useThree();
  const textureMap = useTexture({
    map: mapImg,
  });

  useEffect(() => {}, [placeHighlighted]);

  useEffect(() => {
    Object.entries(materials).forEach(([key, value]) => {
      value.side = THREE.FrontSide;
      value.transparent = true;
      // console.log(value.name);
      if (
        value.name === "PrediosA" ||
        value.name === "PrediosB" ||
        value.name === "PrediosC" ||
        value.name === "PrediosD" ||
        value.name === "EdComercial" ||
        value.name === "EdRes" ||
        value.name === "Hotel"
      ) {
        value.side = THREE.DoubleSide;
        value.envMapIntensity = 1;
        value.roughness = 0;
        value.metalness = 0;
      } else {
        value.envMapIntensity = 1;
        value.roughness = 1;
      }
      if (value.name === "Base") {
        value.emissive = new THREE.Color("#333333");
        value.roughness = 0.1;
        value.metalness = 0;
      }
    });

    Object.entries(nodes).forEach(([key, value]) => {
      // console.log("mesh", value.name);
      if (value.type === "Mesh") {
        if (value.name !== "Base") {
          value.castShadow = true;
          value.receiveShadow = true;
        } else {
          value.castShadow = false;
          value.receiveShadow = true;
        }
      }
    });
    // console.log(actions);
    actions["Sphere.002Action"].loop = THREE.LoopRepeat;
    actions["Sphere.003Action"].loop = THREE.LoopRepeat;

    actions["Sphere.002Action"].play();
    actions["Sphere.003Action"].play();
  }, []);

  useFrame(() => {
    if (placeHighlighted !== null) {
      Object.entries(materials).forEach(([key, value]) => {
        if (typeof placeHighlighted === "string") {
          if (placeHighlighted === value.name) {
            if (value.opacity < 1) {
              value.opacity += 0.025;
              invalidate();
            }
          } else {
            if (value.opacity > 0.15) {
              value.opacity -= 0.025;
              invalidate();
            } else if (value.opacity > 0.15) {
              value.opacity -= 0.025;
              invalidate();
            }
          }
        } else {
          if (placeHighlighted.includes(value.name)) {
            if (value.opacity < 1) {
              value.opacity += 0.025;
              invalidate();
            }
          } else {
            if (value.opacity > 0.15) {
              value.opacity -= 0.025;
              invalidate();
            } else if (value.opacity > 0.15) {
              value.opacity -= 0.025;
              invalidate();
            }
          }
        }
      });
    } else {
      Object.entries(materials).forEach(([key, value]) => {
        if (value.opacity < 1) {
          value.opacity += 0.025;
          invalidate();
        }
      });
    }
  });

  const goOut = () => {
    if (location !== null) {
      let locationProp = locationProps.find((prop) => prop.id === location);
      setData((values) => ({
        ...values,
        camPosX: locationProp.cameraOut.pos.x,
        camPosY: locationProp.cameraOut.pos.y,
        camPosZ: locationProp.cameraOut.pos.z,
        targetPosX: locationProp.cameraOut.target.x,
        targetPosY: locationProp.cameraOut.target.y,
        targetPosZ: locationProp.cameraOut.target.z,
      }));
      setIsAnimating(true);
      setPlaceHighlighted(null);
      setLocation(null);
      refOrbit.current.enableRotate = true;
      refOrbit.current.enableZoom = true;
    }
  };

  return (
    <>
      <Circle
        args={[640, 10]}
        // rotation={[-Math.PI / 2.1, 0, -Math.PI / 4]}
        rotation={[-Math.PI / 2, 0, -Math.PI / 4 + 0.01]}
        position={[3, 8, -1.5]}
        scale={1}
        layer={10}
      >
        <meshBasicMaterial {...textureMap} />
      </Circle>
      <group
        ref={group}
        scale={scale}
        dispose={null}
        onClick={() => {
          goOut();
        }}
      >
        <group name="Scene">
          <mesh
            name="HeliceA"
            castShadow
            receiveShadow
            geometry={nodes.HeliceA.geometry}
            material={materials.Helice}
            position={[30.5, 65.56, -145.19]}
          />
          <mesh
            name="HeliceB"
            castShadow
            receiveShadow
            geometry={nodes.HeliceB.geometry}
            material={materials.Helice}
            position={[70.84, 65.56, -149.37]}
          />
          <mesh
            name="Predios_D"
            castShadow
            receiveShadow
            geometry={nodes.Predios_D.geometry}
            material={materials.PrediosD}
          />
          <mesh
            name="Vegetacao"
            castShadow
            receiveShadow
            geometry={nodes.Vegetacao.geometry}
            material={materials.Vegetacao}
          />
          <mesh
            name="PrediosBaixos"
            castShadow
            receiveShadow
            geometry={nodes.PrediosBaixos.geometry}
            material={materials.PrediosBaixos}
          />
          <mesh
            name="Energia"
            castShadow
            receiveShadow
            geometry={nodes.Energia.geometry}
            material={materials.Energia}
          />
          <mesh
            name="Predios_A"
            castShadow
            receiveShadow
            geometry={nodes.Predios_A.geometry}
            material={materials.PrediosA}
            position={[47.43, 10.86, 111.01]}
            scale={6.59}
          />
          <mesh
            name="Predios_C"
            castShadow
            receiveShadow
            geometry={nodes.Predios_C.geometry}
            material={materials.PrediosC}
          />
          <mesh
            name="Base"
            castShadow
            receiveShadow
            geometry={nodes.Base.geometry}
            material={materials.Base}
          />
          <mesh
            name="EdComercial"
            castShadow
            receiveShadow
            geometry={nodes.EdComercial.geometry}
            material={materials.EdComercial}
            position={[-74.03, 56.23, 51.19]}
          />
          <mesh
            name="EdResidencial"
            castShadow
            receiveShadow
            geometry={nodes.EdResidencial.geometry}
            material={materials.EdRes}
          />
          <mesh
            name="Escola"
            castShadow
            receiveShadow
            geometry={nodes.Escola.geometry}
            material={materials.Escola}
          />
          <mesh
            name="Galpao"
            castShadow
            receiveShadow
            geometry={nodes.Galpao.geometry}
            material={materials.Galpao}
          />
          <mesh
            name="Hospital"
            castShadow
            receiveShadow
            geometry={nodes.Hospital.geometry}
            material={materials.Hospital}
          />
          <mesh
            name="Industria"
            castShadow
            receiveShadow
            geometry={nodes.Industria.geometry}
            material={materials.Industria}
          />
          <mesh
            name="Casa"
            castShadow
            receiveShadow
            geometry={nodes.Casa.geometry}
            material={materials.Casa}
          />
          <mesh
            name="Shopping"
            castShadow
            receiveShadow
            geometry={nodes.Shopping.geometry}
            material={materials.Shopping}
            position={[31.86, 25.23, 7.59]}
          />
          <mesh
            name="Escritorio"
            castShadow
            receiveShadow
            geometry={nodes.Escritorio.geometry}
            material={materials.Escritorio}
          />
          <mesh
            name="Hotel"
            castShadow
            receiveShadow
            geometry={nodes.Hotel.geometry}
            material={materials.Hotel}
            position={[66.61, 10.86, 111.45]}
          />
          <mesh
            name="Predios_B002"
            castShadow
            receiveShadow
            geometry={nodes.Predios_B002.geometry}
            material={materials.PrediosB}
          />
        </group>
      </group>
    </>
  );
}

useGLTF.preload(model);
