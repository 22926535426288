import { Arrow, ArrowUp } from "../../assets/images";
const productsEdificioResidencial = [
  {
    id: 0,
    name: "Subir",
    environmentArea: 0,
    category: { image: ArrowUp },
    pinPos: [310, 337, -61],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 314.98858959613983,
        y: 650.1742480495607,
        z: -69.58620861537187,
      },
      target: {
        x: 294.32783210205434,
        y: 638.5266933326376,
        z: -103.97560125467244,
      },
      posMobile: {
        x: 314.98858959613983,
        y: 650.1742480495607,
        z: -69.58620861537187,
      },
      targetMobile: {
        x: 294.32783210205434,
        y: 638.5266933326376,
        z: -103.97560125467244,
      },
    },
    cameraOut: {
      pos: {
        x: 314.98858959613983,
        y: 650.1742480495607,
        z: -69.58620861537187,
      },
      target: {
        x: 294.32783210205434,
        y: 638.5266933326376,
        z: -103.97560125467244,
      },
    },
  },
  {
    id: -1,
    name: "Descer",
    environmentArea: 0,
    category: { image: Arrow },
    pinPos: [311, 420, -600],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 84.50369154431179,
        y: 230.68183835456134,
        z: 214.4330955821151,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
      posMobile: {
        x: 84.50369154431179,
        y: 230.68183835456134,
        z: 214.4330955821151,
      },
      targetMobile: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
    cameraOut: {
      pos: {
        x: 84.50369154431179,
        y: 230.68183835456134,
        z: 214.4330955821151,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 1,
    name: "Placa Gesso Performa RU Placo",
    pinPos: [-357.7922694367982, 242.4628969825785, -414.2860073054396],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -86.90906859448638,
        y: 199.4076166039667,
        z: -135.06140776478262,
      },
      target: {
        x: -87.70039749942529,
        y: 199.5446692820291,
        z: -135.65723922387494,
      },
      posMobile: {
        x: -86.90906859448638,
        y: 199.4076166039667,
        z: -135.06140776478262,
      },
      targetMobile: {
        x: -87.70039749942529,
        y: 199.5446692820291,
        z: -135.65723922387494,
      },
    },
    cameraOut: {
      pos: {
        x: 73.44909236845264,
        y: 227.59094091042505,
        z: 171.64003110061796,
      },
      target: {
        x: 72.86658971074979,
        y: 227.59203353136377,
        z: 170.82720303764978,
      },
    },
  },
  {
    id: 6,
    name: "supergraute quartzolit",
    pinPos: [348.09996577439284, 222.56325650809788, 347.70325457932745],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 86.0642849266388,
        y: 39.98313964172742,
        z: 147.61925171829392,
      },
      target: {
        x: 86.83903118769955,
        y: 40.48601389524662,
        z: 148.00250844180954,
      },
      posMobile: {
        x: 86.0642849266388,
        y: 39.98313964172742,
        z: 147.61925171829392,
      },
      targetMobile: {
        x: 86.83903118769955,
        y: 40.48601389524662,
        z: 148.00250844180954,
      },
    },
    cameraOut: {
      pos: {
        x: 72.05584635066027,
        y: 227.62644406640436,
        z: 170.18050224220664,
      },
      target: {
        x: 72.887972204578,
        y: 227.60102969668247,
        z: 170.73450645974773,
      },
    },
  },
  {
    id: 17,
    name: "monocapa natura quartzolit",
    pinPos: [344.0400751924547, 630.1274035368011, -300.11738561218897],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 234.11256168480443,
        y: 469.874125346434,
        z: -24.4593222178393,
      },
      target: {
        x: 234.2694054513116,
        y: 470.3630114077974,
        z: -25.317454172676356,
      },
      posMobile: {
        x: 234.11256168480443,
        y: 469.874125346434,
        z: -24.4593222178393,
      },
      targetMobile: {
        x: 234.2694054513116,
        y: 470.3630114077974,
        z: -25.317454172676356,
      },
    },
    cameraOut: {
      pos: {
        x: 73.61783766020817,
        y: 230.09441028857006,
        z: 174.71473368644845,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 19,
    name: "Painel Wallfelt Isover",
    environmentArea: 0,
    pinPos: [33.552451922244074, 658.4435813435248, -790.8191837124085],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 350.6777114131429,
        y: 640.124956300894,
        z: -571.8180628704131,
      },
      target: {
        x: 349.76636548590193,
        y: 640.1274920981647,
        z: -572.2296964096606,
      },
      posMobile: {
        x: 350.6777114131429,
        y: 640.124956300894,
        z: -571.8180628704131,
      },
      targetMobile: {
        x: 349.76636548590193,
        y: 640.1274920981647,
        z: -572.2296964096606,
      },
    },
    cameraOut: {
      pos: {
        x: 294.630474947191,
        y: 638.3676019585774,
        z: -102.97565483939529,
      },
      target: {
        x: 294.27438477689714,
        y: 638.4277443476473,
        z: -103.90816901283024,
      },
    },
  },
  {
    id: 26,
    name: "Painel Masterboard Brasilit",
    environmentArea: 0,
    pinPos: [283.71404592010964, 421.1269351665455, -853.1794410365075],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 234.03479277547564,
        y: 582.0209021908722,
        z: -684.7009127211803,
      },
      target: {
        x: 234.09756844616896,
        y: 581.3552375289482,
        z: -685.444518647081,
      },
      posMobile: {
        x: 234.03479277547564,
        y: 582.0209021908722,
        z: -684.7009127211803,
      },
      targetMobile: {
        x: 234.09756844616896,
        y: 581.3552375289482,
        z: -685.444518647081,
      },
    },
    cameraOut: {
      pos: {
        x: 294.077353390815,
        y: 638.3608116910166,
        z: -102.71719196545219,
      },
      target: {
        x: 294.0942009002046,
        y: 638.0941633563082,
        z: -103.68083859520014,
      },
    },
  },
  {
    id: 27,
    name: "Painel Fiberwood Prime Sonex",
    pinPos: [-358.64023739859965, 216.72615696860527, -107.91674814606037],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 20.631780679269525,
        y: 82.40683425532279,
        z: 22.105348489252666,
      },
      target: {
        x: 19.685298107852784,
        y: 82.69236055238242,
        z: 21.954864351577726,
      },
      posMobile: {
        x: 20.631780679269525,
        y: 82.40683425532279,
        z: 22.105348489252666,
      },
      targetMobile: {
        x: 19.685298107852784,
        y: 82.69236055238242,
        z: 21.954864351577726,
      },
    },
    cameraOut: {
      pos: {
        x: 73.72347109488919,
        y: 227.7511558464531,
        z: 171.48355957988713,
      },
      target: {
        x: 72.89990689250472,
        y: 227.68281073130305,
        z: 170.92046904561582,
      },
    },
  },
  {
    id: 28,
    name: "Painel Nexacustic 16RF Sonex",
    environmentArea: 0,
    pinPos: [-354.7177083841259, 563.2418557447874, -438.68874138935865],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -146.92897565559463,
        y: 614.6760508215738,
        z: -226.98458257574862,
      },
      target: {
        x: -147.71445076681292,
        y: 614.468770140119,
        z: -227.56773227244085,
      },
      posMobile: {
        x: -146.92897565559463,
        y: 614.6760508215738,
        z: -226.98458257574862,
      },
      targetMobile: {
        x: -147.71445076681292,
        y: 614.468770140119,
        z: -227.56773227244085,
      },
    },
    cameraOut: {
      pos: {
        x: 295.0182686465625,
        y: 638.5245159715174,
        z: -103.74478657993158,
      },
      target: {
        x: 294.12141956954326,
        y: 638.4233974497192,
        z: -104.1754102875919,
      },
    },
  },
  {
    id: 40,
    name: "impermeabilizante tecplus flex quartzolit",
    pinPos: [171.02950206098402, 25.636137307416266, -502.58239404560476],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 197.02452498978002,
        y: 269.60519119964755,
        z: -286.8841458484959,
      },
      target: {
        x: 196.77194991479686,
        y: 268.8391467565286,
        z: -287.47523107303107,
      },
      posMobile: {
        x: 197.02452498978002,
        y: 269.60519119964755,
        z: -286.8841458484959,
      },
      targetMobile: {
        x: 196.77194991479686,
        y: 268.8391467565286,
        z: -287.47523107303107,
      },
    },
    cameraOut: {
      pos: {
        x: 72.66884341241912,
        y: 227.86001672557353,
        z: 171.7701141729736,
      },
      target: {
        x: 72.86658971074979,
        y: 227.59203353136377,
        z: 170.82720303764978,
      },
    },
  },
  {
    id: 43,
    name: "impermeabilizante tecplus friso quartzolit",
    pinPos: [348.38122257957855, 624.1918863548483, 24.27599952320637],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 110.53109472835328,
        y: 481.72251006563295,
        z: 58.34622552186516,
      },
      target: {
        x: 111.34299174376683,
        y: 482.23531675108524,
        z: 58.06720484213332,
      },
      posMobile: {
        x: 110.53109472835328,
        y: 481.72251006563295,
        z: 58.34622552186516,
      },
      targetMobile: {
        x: 111.34299174376683,
        y: 482.23531675108524,
        z: 58.06720484213332,
      },
    },
    cameraOut: {
      pos: {
        x: 73.42036374570297,
        y: 229.94784831377655,
        z: 174.35740059043798,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 44,
    name: "piso antirruído quartzolit",
    pinPos: [-213.34423462128828, 26.618191572519876, 198.51818365835496],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 148.4833865825966,
        y: 200.53123945020334,
        z: 167.91481274080195,
      },
      target: {
        x: 147.60844882327223,
        y: 200.10780619387373,
        z: 168.14973441869174,
      },
      posMobile: {
        x: 148.4833865825966,
        y: 200.53123945020334,
        z: 167.91481274080195,
      },
      targetMobile: {
        x: 147.60844882327223,
        y: 200.10780619387373,
        z: 168.14973441869174,
      },
    },
    cameraOut: {
      pos: {
        x: 74.82351036648988,
        y: 231.24524514448947,
        z: 173.94985761144076,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
];

export default productsEdificioResidencial;
