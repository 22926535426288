import styled from "styled-components";
import { theme } from "../../../../theme";

export const TextContainer = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  padding: 10px 15px;
`;

export const Title = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  white-space: pre;
  ${theme.fonts.h3};
  color: ${theme.colors.black};
  .count {
    color: ${theme.colors.greylight};
  }
`;

export const Description = styled.div`
  ${theme.fonts.caption}
  color: ${theme.colors.greyDark};
  margin-top: 5px;
`;
