const productsShopping = [
  {
    id: 5,
    name: "Placa Placa Gesso 4PRO Placo",
    pinPos: [-446.7084168143268, 573.3813956810592, 32.790421765492056],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -358.90693021614095,
        y: 497.1504703023072,
        z: 235.58926016035895,
      },
      target: {
        x: -359.4242018302082,
        y: 497.468785799344,
        z: 234.79483878500045,
      },
      posMobile: {
        x: -358.90693021614095,
        y: 497.1504703023072,
        z: 235.58926016035895,
      },
      targetMobile: {
        x: -359.4242018302082,
        y: 497.468785799344,
        z: 234.79483878500045,
      },
    },
    cameraOut: {
      pos: {
        x: 74.71969131726256,
        y: 230.12393359312466,
        z: 174.41551397575498,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 6,
    name: "supergraute quartzolit",
    pinPos: [670.9895533577816, 99.96208377384278, 394.5324773826952],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 278.5715456226817,
        y: 305.2376310422277,
        z: 575.1961418277516,
      },
      target: {
        x: 279.30238965109066,
        y: 304.7856847000785,
        z: 574.6846621019004,
      },
      posMobile: {
        x: 278.5715456226817,
        y: 305.2376310422277,
        z: 575.1961418277516,
      },
      targetMobile: {
        x: 279.30238965109066,
        y: 304.7856847000785,
        z: 574.6846621019004,
      },
    },
    cameraOut: {
      pos: {
        x: 73.0654867565636,
        y: 230.76236557827332,
        z: 174.41762303688614,
      },
      target: {
        x: 73.99524862762327,
        y: 230.65649998898337,
        z: 174.06501087015346,
      },
    },
  },
  {
    id: 20,
    name: "Feltro Facefelt Isover",
    pinPos: [1197.7374109401103, 624.4749426393465, 18.062245907443764],
    scale: 2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 545.107137333975,
        y: 261.27263870208867,
        z: 263.77227644066494,
      },
      target: {
        x: 545.883972418142,
        y: 261.7146451153261,
        z: 263.32377049570516,
      },
      posMobile: {
        x: 545.107137333975,
        y: 261.27263870208867,
        z: 263.77227644066494,
      },
      targetMobile: {
        x: 545.883972418142,
        y: 261.7146451153261,
        z: 263.32377049570516,
      },
    },
    cameraOut: {
      pos: {
        x: 73.09379754866522,
        y: 230.29371485286433,
        z: 174.48936943496003,
      },
      target: {
        x: 73.90346519097716,
        y: 230.6476305182872,
        z: 174.02120065048018,
      },
    },
  },
  {
    id: 24,
    name: "Sistema Nextera com Placa Cimentícia Brasilit",
    pinPos: [1747.6691864907684, 294.44569298751935, 474.3187806735562],
    scale: 2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 1427.936474564934,
        y: 308.7474803057545,
        z: 1072.019035450072,
      },
      target: {
        x: 1428.2496323736607,
        y: 308.7328319511674,
        z: 1071.0694473222284,
      },
      posMobile: {
        x: 1427.936474564934,
        y: 308.7474803057545,
        z: 1072.019035450072,
      },
      targetMobile: {
        x: 1428.2496323736607,
        y: 308.7328319511674,
        z: 1071.0694473222284,
      },
    },
    cameraOut: {
      pos: {
        x: 73.03763443095248,
        y: 230.6688309898256,
        z: 174.5210882728531,
      },
      target: {
        x: 73.90346519097716,
        y: 230.6476305182872,
        z: 174.02120065048018,
      },
    },
  },
  {
    id: 37,
    name: "Baffles Nexacustic 40 Sonex",
    pinPos: [37.474586897589816, 520.9425095615244, -353.81329795763793],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -39.754694454793096,
        y: 339.1991144694353,
        z: -27.553075983298598,
      },
      target: {
        x: -39.69735059067767,
        y: 339.6952497711839,
        z: -28.419425476059534,
      },
      posMobile: {
        x: -39.754694454793096,
        y: 339.1991144694353,
        z: -27.553075983298598,
      },
      targetMobile: {
        x: -39.69735059067767,
        y: 339.6952497711839,
        z: -28.419425476059534,
      },
    },
    cameraOut: {
      pos: {
        x: 74.00942336048263,
        y: 230.17609040353224,
        z: 174.8966564097804,
      },
      target: {
        x: 73.90346519097716,
        y: 230.6476305182872,
        z: 174.02120065048018,
      },
    },
  },
  {
    id: 38,
    name: "Placa Rigitone 12/25 Q Placo",
    pinPos: [-959.6675090595672, 641.0787955924909, 0],
    scale: 1.6,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -701.838531141485,
        y: 509.856494005615,
        z: 219.4637715206689,
      },
      target: {
        x: -702.6453575988378,
        y: 510.2198690510165,
        z: 218.9979500483269,
      },
      posMobile: {
        x: -701.838531141485,
        y: 509.856494005615,
        z: 219.4637715206689,
      },
      targetMobile: {
        x: -702.6453575988378,
        y: 510.2198690510165,
        z: 218.9979500483269,
      },
    },
    cameraOut: {
      pos: {
        x: 74.7874113098443,
        y: 230.26385758572667,
        z: 174.45461213170157,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 39,
    name: "impermeabilizante tecplus top quartzolit",
    pinPos: [-263.59952932865565, 21.693786457882116, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -136.8526138537071,
        y: 159.8904256147496,
        z: 221.0989296878758,
      },
      target: {
        x: -137.4361164262705,
        y: 159.4229049622459,
        z: 220.43488963841008,
      },
      posMobile: {
        x: -136.8526138537071,
        y: 159.8904256147496,
        z: 221.0989296878758,
      },
      targetMobile: {
        x: -137.4361164262705,
        y: 159.4229049622459,
        z: 220.43488963841008,
      },
    },
    cameraOut: {
      pos: {
        x: 74.764937521085,
        y: 231.14902185302856,
        z: 174.44163688371168,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 49,
    name: "contrapiso autonivelante RAD quartzolit",
    pinPos: [-1001.465184063094, 26.733403941867586, 0],
    scale: 1.4,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -744.4731048455594,
        y: 418.58828079325116,
        z: 260.856479655096,
      },
      target: {
        x: -745.0297752447858,
        y: 417.8222363501322,
        z: 260.5350858502527,
      },
      posMobile: {
        x: -744.4731048455594,
        y: 418.58828079325116,
        z: 260.856479655096,
      },
      targetMobile: {
        x: -745.0297752447858,
        y: 417.8222363501322,
        z: 260.5350858502527,
      },
    },
    cameraOut: {
      pos: {
        x: 74.86049802115886,
        y: 230.79296587886654,
        z: 174.4968087641532,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 57,
    name: "Nexacustic Ways",
    pinPos: [-709.8900955696168, 230.0769107952679, -698.9906345425504],
    scale: 1.5,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -385.42215757751484,
        y: 229.27582529451158,
        z: -421.51630365943765,
      },
      target: {
        x: -386.28814021352747,
        y: 229.28576337780413,
        z: -422.0162789674531,
      },
      posMobile: {
        x: -385.42215757751484,
        y: 229.27582529451158,
        z: -421.51630365943765,
      },
      targetMobile: {
        x: -386.28814021352747,
        y: 229.28576337780413,
        z: -422.0162789674531,
      },
    },
    cameraOut: {
      pos: {
        x: 74.64027759995231,
        y: 230.65327872074766,
        z: 174.69727429424185,
      },
      target: {
        x: 73.90346519097716,
        y: 230.6476305182872,
        z: 174.02120065048018,
      },
    },
  },
];

export default productsShopping;
