import React, { useEffect } from "react";
import { Container, Logo } from "./style";
import BurgerMenu from "./BurgerMenu";

const Header = ({ burgerMenuOn, setBurgerMenuOn, className }) => {
  return (
    <Container className={className}>
      <BurgerMenu
        burgerMenuOn={burgerMenuOn}
        setBurgerMenuOn={setBurgerMenuOn}
      />
    </Container>
  );
};

export default Header;
