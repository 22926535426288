import { useState, useEffect } from "react";
import { isBrowser, isMobile } from "react-device-detect";
import { Card, Accordion, useAccordionButton } from "react-bootstrap";

import {
  Container,
  Logo,
  SecondaryMenuButton,
  MenuContainer,
  MenuTop,
  Menu,
  MenuBottom,
  TabMenu,
  TabMenuItem,
  Tab,
  TabItemArrow,
  TabItem,
  TabItemSolucoes,
  TitSolucoes,
  TabSubitems,
  TabSubitem,
} from "./style.js";
import { SelectObject } from "styled-icons/fluentui-system-filled";
const HomeMenu = ({
  firstView,
  setPlaceHighlighted,
  location,
  setLocation,
  setData,
  locationProps,
  refOrbit,
  locationData,
  className,
  tabMenu,
  setTabMenu,
  secondaryMenuOn,
  setSecondaryMenuOn,
  isAnimating,
  setIsAnimating,
  persona,
}) => {
  const clone = require("rfdc")();

  const clickTabMenu = (index) => {
    const newTabMenu = tabMenu.map((tab, i) => {
      if (i === index) {
        return { ...tab, selected: true };
      } else {
        return { ...tab, selected: false };
      }
    });
    setTabMenu(newTabMenu);
  };

  const selectItem = (index, itemIndex) => {
    setSecondaryMenuOn(false);
    let newTabMenu = clone(tabMenu);
    newTabMenu.map((tab, i) => {
      tab.items.map((item, o) => {
        if (i === index && item.id === itemIndex) {
          item.selected = true;
          if (index === 0) {
            //TAB LOCAIS
            setPlaceHighlighted(item.materialId);
            setLocation(itemIndex);
            setData((values) => ({
              ...values,
              camPosX: isMobile
                ? item.cameraIn.posMobile.x
                : item.cameraIn.pos.x,
              camPosY: isMobile
                ? item.cameraIn.posMobile.y
                : item.cameraIn.pos.y,
              camPosZ: isMobile
                ? item.cameraIn.posMobile.z
                : item.cameraIn.pos.z,
              targetPosX: isMobile
                ? item.cameraIn.targetMobile.x
                : item.cameraIn.target.x,
              targetPosY: isMobile
                ? item.cameraIn.targetMobile.y
                : item.cameraIn.target.y,
              targetPosZ: isMobile
                ? item.cameraIn.targetMobile.z
                : item.cameraIn.target.z,
              animationSpeed: item.animationSpeed,
            }));
            setIsAnimating(true);
            refOrbit.current.enableRotate = false;
            refOrbit.current.enableZoom = false;
          } else {
            //TAB SOLUÇÕES
          }
          return item;
        } else {
          item.selected = false;
          return item;
        }
      });
    });
    setTabMenu(newTabMenu);
  };

  const setItemHover = (index, itemIndex, value) => {
    let newTabMenu = clone(tabMenu);
    // console.log(newTabMenu);
    newTabMenu.map((tab, i) => {
      tab.items.map((item, o) => {
        if (value) {
          if (i === index && item.id === itemIndex) {
            item.hovered = true;
            return item;
          } else {
            item.hovered = false;
            return item;
          }
        } else {
          item.hovered = false;
          return item;
        }
      });
    });
    setTabMenu(newTabMenu);
  };

  const setItemSolucoes = (index, value) => {
    let newTabMenu = clone(tabMenu);
    // console.log(newTabMenu);
    newTabMenu.map((tab, i) => {
      tab.items.map((item, o) => {
        if (value) {
          if (
            i === index &&
            item.name.trim().toLowerCase() === value.trim().toLowerCase()
          ) {
            item.selected = true;
            return item;
          } else {
            item.selected = false;
            return item;
          }
        } else {
          item.selected = false;
          return item;
        }
      });
    });
    // console.log(newTabMenu);
    setTabMenu(newTabMenu);
  };

  const selectItemSolucoes = (index, itemIndex, subitemIndex) => {
    // console.log("clicked subitem");
    if (isMobile) {
      toogleSecondaryMenu();
    }
    let newTabMenu = clone(tabMenu);
    let name = null;
    newTabMenu.map((tab, i) => {
      tab.items.map((item, o) => {
        // console.log(item);
        item.micro_solutions !== undefined &&
          item.micro_solutions.map((category, o) => {
            if (
              i === index &&
              item.id === itemIndex &&
              category.id === subitemIndex
            ) {
              name = category.environments;

              category.selected = true;
              return item;
            } else {
              category.selected = false;
              return item;
            }
          });
      });
    });

    let materialsIds = [];

    newTabMenu.map((tab, i) => {
      tab.items.map((item, o) => {
        if (name && item.name) {
          name.map((nome) => {
            if (
              i === 0 &&
              nome &&
              item.name.trim().toLowerCase() === nome.trim().toLowerCase()
            ) {
              materialsIds.push(item.materialId);
            }
          });
        }
      });
    });

    // console.log("ids", materialsIds);
    setPlaceHighlighted(materialsIds);

    setTabMenu(newTabMenu);
  };

  const setItemHoverSolucoes = (index, itemIndex, value) => {
    setSecondaryMenuOn(false);
    let newTabMenu = clone(tabMenu);
    newTabMenu.map((tab, i) => {
      tab.items.map((item, o) => {
        if (value) {
          if (i === index && item.id === itemIndex) {
            item.hovered = true;
            return item;
          } else {
            item.hovered = false;
            return item;
          }
        } else {
          item.hovered = false;
          return item;
        }
      });
    });
    setTabMenu(newTabMenu);
  };

  const toogleSecondaryMenu = () => {
    if (!secondaryMenuOn) {
      setPlaceHighlighted(null);
      setLocation(null);
      refOrbit.current.enableRotate = false;
      refOrbit.current.enableZoom = false;
      setSecondaryMenuOn(true);
    } else {
      refOrbit.current.enableRotate = true;
      refOrbit.current.enableZoom = true;
      setSecondaryMenuOn(false);
    }
    setIsAnimating(true);
  };

  function CustomToggle(props) {
    const decoratedOnClick = useAccordionButton(props.eventKey, (e) => {
      e.stopPropagation();
      e.preventDefault();
      // setTimeout(() => {
      //   props.selectItem(props.index, props.itemId);
      // }, 500);
    });

    return (
      <TabItemSolucoes
        onClick={decoratedOnClick}
        // onPointerOver={(e) => {
        //   e.preventDefault();
        //   e.stopPropagation();
        //   props.setItemHoverSolucoes(props.index, props.itemId, true);
        // }}
        // onPointerOut={(e) => {
        //   e.preventDefault();
        //   e.stopPropagation();
        //   props.setItemHoverSolucoes(props.index, props.itemId, false);
        // }}
        className={`${className}`}
      >
        {props.children}
      </TabItemSolucoes>
    );
  }

  useEffect(() => {
    // console.log(tabMenu);
  }, [tabMenu]);

  useEffect(() => {
    if (persona !== null) {
      console.log("persona order");
      let selectedPersona = persona.find((element) => element.selected);

      let tabMenuTemp = clone(tabMenu);
      if (selectedPersona) {
        tabMenuTemp[0].items.forEach((item, index) => {
          item.persona = -1;
          selectedPersona.environments.map((element2) => {
            if (element2.name === item.name) {
              item.persona = index;
            }
          });
        });
        // console.log(tabMenuTemp[0].items);
        tabMenuTemp[0].items.sort((a, b) => (a.persona > b.persona ? -1 : 1));
        tabMenuTemp[0].items.sort((a, b) => {
          if (a.persona >= 0 && b.persona >= 0) {
            return a.name < b.name ? -1 : 1;
          } else {
            return 1;
          }
        });

        setTabMenu(tabMenuTemp);
      }
    }
  }, [persona]);

  return (
    <>
      {isMobile && !firstView && (
        <SecondaryMenuButton onPointerDown={toogleSecondaryMenu} />
      )}
      <Container className={className}>
        {/* <Logo /> */}
        <TabMenu className={className}>
          {tabMenu.map((item, index) => (
            <TabMenuItem
              key={index}
              className={item.selected && "selected"}
              onPointerDown={() => clickTabMenu(index)}
            >
              {item.name}
            </TabMenuItem>
          ))}
        </TabMenu>
        <MenuContainer className={className}>
          <MenuTop className={className} />
          <Menu className={className}>
            <Tab className={className}>
              {tabMenu.map(
                (item, index) =>
                  item.selected &&
                  index === 0 &&
                  item.items.map((itemIn, itemIndex) => {
                    return (
                      <TabItem
                        key={itemIndex}
                        onClick={() => selectItem(index, itemIn.id)}
                        className={`${itemIn.selected &&
                          "selected"} ${className} ${persona !== null &&
                          persona
                            .find((element) => element.selected)
                            ?.environments.find(
                              (element2) => element2.name === itemIn.name
                            ) &&
                          "bold"}`}
                        onPointerOver={() =>
                          setItemHover(index, itemIn.id, true)
                        }
                        onPointerOut={() =>
                          setItemHover(index, itemIn.id, false)
                        }
                      >
                        {/* <TabItemArrow
                          className={
                            (itemIn.hovered && "hovered") ||
                            (itemIn.selected && "hovered")
                          }
                        /> */}
                        {itemIn.name.toLowerCase()}
                      </TabItem>
                    );
                  })
              )}
              {tabMenu.map((item, index) => (
                <Accordion key={index} defaultActiveKey="0">
                  {item.selected &&
                    index === 1 &&
                    item.items.map((itemIn, itemIndex) => {
                      return (
                        <CustomToggle
                          key={itemIndex}
                          eventKey={itemIndex}
                          index={index}
                          itemId={itemIn.id}
                          selectItem={selectItem}
                          // setItemHoverSolucoes={setItemHoverSolucoes}
                        >
                          <TabItemArrow
                            className={
                              (itemIn.hovered && "hovered accordion") ||
                              (itemIn.selected && "hovered accordion")
                            }
                          />
                          <TitSolucoes
                            // onPointerDown={(e) => {
                            //   // e.preventDefault();
                            //   // e.stopPropagation();
                            //   setTimeout(() => {
                            //     selectItem(index, itemIn.id);
                            //   }, 500);
                            // }}
                            className={itemIn.selected && "selected"}
                          >
                            {itemIn.name.toLowerCase()}
                          </TitSolucoes>
                          <TabSubitems>
                            {itemIn.micro_solutions.map(
                              (subitem, subitemIndex) => {
                                return (
                                  <Accordion.Collapse
                                    key={subitemIndex}
                                    eventKey={itemIndex}
                                  >
                                    <TabSubitem
                                      key={subitemIndex}
                                      onClick={(e) => {
                                        e.stopPropagation();
                                        selectItemSolucoes(
                                          index,
                                          itemIn.id,
                                          subitem.id
                                        );
                                      }}
                                      className={subitem.selected && "selected"}
                                    >
                                      {subitem.name.toLowerCase()}
                                    </TabSubitem>
                                  </Accordion.Collapse>
                                );
                              }
                            )}
                          </TabSubitems>
                        </CustomToggle>
                      );
                    })}
                </Accordion>
              ))}
            </Tab>
          </Menu>
          <MenuBottom className={className} />
        </MenuContainer>
      </Container>
    </>
  );
};

export default HomeMenu;
