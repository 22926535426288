import styled from "styled-components";
import { theme } from "../../../../theme";

export const TextContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  padding: 5px 10px;
`;

export const Title = styled.div`
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  ${theme.fonts.bodytitle};
  text-transform: capitalize;
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.black};
  text-align: center;
  margin: 10px 0px;
`;

export const Description = styled.div`
  width: 100%;
  ${theme.fonts.body};
  font-size: 13px;
  line-height: 14px;
  color: ${theme.colors.blue};
  text-align: center;
  margin-bottom: 10px;
`;
