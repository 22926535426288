import { useState, useEffect } from "react";
import nuvem1 from "../../../../assets/images/nuvem1.png";
import nuvem2 from "../../../../assets/images/nuvem2.png";
import { ContainerNuvens, Nuvem } from "./styled";
import { isMobile, isBrowser } from "react-device-detect";

const Nuvens = ({ firstView }) => {
  return (
    <ContainerNuvens>
      <Nuvem className={`nuvem1 ${!firstView && "in"} ${isMobile && "mobile"}`} src={nuvem1} />
      <Nuvem className={`nuvem2 ${!firstView && "in"} ${isMobile && "mobile"}`} src={nuvem1} />
      <Nuvem className={`nuvem3 ${!firstView && "in"} ${isMobile && "mobile"}`} src={nuvem1} />
      <Nuvem className={`nuvem4 ${!firstView && "in"} ${isMobile && "mobile"}`} src={nuvem2} />
    </ContainerNuvens>
  );
};

export default Nuvens;
