import styled from "styled-components";

export const Container = styled.div`
  // width: 935px;
  // padding: 20px 40px;
  // @media only screen and (max-width: 600px) {
  //   width: 100%;
  //   padding: 20px 20px;
  // }
`;

export const AllBlocks = styled.div`
  user-select: none;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  @media only screen and (max-width: 600px) {
    flex-flow: row wrap;
    overflow-x: hidden;
    overflow-y: auto;
  }
`;

export const LeftBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
    overflow-y: hidden;
  }
`;

export const RightBlock = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 300px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: hidden;
  @media only screen and (max-width: 600px) {
    width: 100%;
    height: auto;
  }
`;

export const FirstBlock = styled.div`
  position: relative;
  width: 100%;
  height: auto;
  background-color: ${({ theme }) => theme.colors.white};
  padding: 20px 30px 20px;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FirstBlockTop = styled.div`
  display: flex;
  flex-flow: row nowrap;
  overflow: hidden;
  width: 100%;
  margin-top: 20px;
  @media only screen and (max-width: 600px) {
    flex-flow: column-reverse wrap;
  }
`;

export const FirstBlockImage = styled.div`
  width: 50%;
  max-height: 300px;
  margin-top: 20px;
  background: #ffffff;
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;
export const FirstBlockTopRight = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: flex-start;
  justify-content: flex-start;
  overflow: hidden;
  width: 50%;
  padding: 20px 0px 0px 20px;
  box-sizing: border-box;
  @media only screen and (max-width: 600px) {
    width: 100%;
    padding: 20px 0px 0px 0px;
  }
`;

export const FirstBlockLogo = styled.div`
  width: 100%;
  height: 100px;
  ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.bodytitle};
  @media only screen and (max-width: 600px) {
    font-size: 32px;
    line-height: 40px;
  }
`;
export const FirstBlockTopRightText = styled.div`
  width: 100%;
  height: 100%;
  ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.black};

  @media only screen and (max-width: 600px) {
    // font-size: 32px;
    // line-height: 40px;
  }
`;
export const FirstBlockTitle = styled.div`
  width: 100%;
  ${({ theme }) => theme.fonts.displaysmall};
  font-size: 32px;
  line-height: 41px;
  text-transform: none;
  letter-spacing: 0px;
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    font-size: 28px;
    line-height: 30px;
  }
`;

export const FirstBlockText = styled.div`
  width: 100%;
  height: 100%;
  padding-top: 20px;
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    font-size: 16px;
    line-height: 24px;
  }
`;

export const FirstBlockThumbs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: calc(100% + 80px);
  height: auto;
  margin-top: 40px;
  margin-left: -40px;
  overflow-x: auto;
  padding: 40px 40px 20px;
  background-color: ${({ theme }) => theme.colors.greylightest};

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const FirstBlockThumb = styled.img`
  cursor: grab;
  flex-shrink: 0;
  width: 160px;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.offwhite};
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 20px;
  object-fit: contain;
  @media only screen and (max-width: 600px) {
    width: 129px;
    height: 129px;
  }
`;

export const SecondBlock = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.offwhite};
  padding: 40px 30px;
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const ThirdBlock = styled.div`
  width: 100%;
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: 40px 30px;
  color: ${({ theme }) => theme.colors.black};
  @media only screen and (max-width: 600px) {
    width: 100%;
  }
`;

export const FirstBlockRight = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.blue};
  padding: 40px 30px 20px;
`;

export const FirstBlockRightTitle = styled.div`
  ${({ theme }) => theme.fonts.caption};
  color: ${({ theme }) => theme.colors.offwhite};
  font-size: 14px;
`;

export const FirstBlockRightThumbs = styled.div`
  display: flex;
  flex-flow: column nowrap;
  align-items: flex-start;
  justify-content: flex-start;
  width: 100%;
  height: 100%;
  margin-top: 40px;
  padding-bottom: 40px;
  overflow-x: hidden;
  overflow-y: auto;

  &::-webkit-scrollbar {
    display: none;
  }
  @media only screen and (max-width: 600px) {
    flex-flow: row nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
`;

export const FirstBlockRightThumbContainer = styled.div`
  width: 160px;
  height: auto;
  padding-bottom: 20px;

  @media only screen and (max-width: 600px) {
    width: 129px;
    margin-right: 20px;
  }
`;

export const FirstBlockRightThumb = styled.img`
  width: 160px;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  margin-bottom: 10px;
  object-fit: contain;
  @media only screen and (max-width: 600px) {
    width: 129px;
    height: 129px;
  }
`;

export const FirstBlockRightThumbTitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.offwhite};

  // &:after {
  //   content: " ↗";
  //   white-space: pre;
  //   color: ${({ theme }) => theme.colors.offwhite};
  // }
`;

export const SecondBlockRight = styled.div`
  display: flex;
  flex-flow: column wrap;
  width: 100%;
  background-color: ${({ theme }) => theme.colors.yellow};
  padding: 40px 30px 20px;
`;

export const SecondBlockRightTitle = styled.div`
  ${({ theme }) => theme.fonts.caption};
  color: ${({ theme }) => theme.colors.black};
  font-size: 14px;
`;

export const SecondBlockRightThumbs = styled.div`
  display: flex;
  flex-flow: row wrap;
  width: 100%;
  margin-top: 40px;
`;

export const SecondBlockRightThumbContainer = styled.div`
  width: 160px;
  height: auto;
  padding-bottom: 40px;
  @media only screen and (max-width: 600px) {
    width: 129px;
    margin-right: 20px;
  }
`;

export const SecondBlockRightThumb = styled.div`
  width: 160px;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.offwhite};
  border-radius: 8px;
  margin-bottom: 10px;
  @media only screen and (max-width: 600px) {
    width: 129px;
    height: 129px;
  }
`;

export const SecondBlockRightThumbTitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.offwhite};

  &:after {
    content: " ↗";
    white-space: pre;
    color: ${({ theme }) => theme.colors.sky};
  }
`;

export const SecondBlockRightThumbSubtitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.greylight};
`;

export const ThirdBlockLeftTitle = styled.div`
  ${({ theme }) => theme.fonts.caption};
  color: ${({ theme }) => theme.colors.blue};
  font-size: 14px;
`;

export const ThirdBlockLeftThumbs = styled.div`
  display: flex;
  flex-flow: row nowrap;
  width: 100%;
  margin-top: 40px;
  overflow-x: auto;
  cursor: grab;

  // overflow-y: hidden;
  &::-webkit-scrollbar {
    display: none;
  }
`;

export const ThirdBlockLeftThumbContainer = styled.div`
  width: 170px;
  padding: 0px 10px 40px;
  margin-right: 20px;
  @media only screen and (max-width: 600px) {
    width: 129px;
  }
`;

export const ThirdBlockLeftThumb = styled.img`
  width: 160px;
  height: 160px;
  background-color: ${({ theme }) => theme.colors.white};
  border-radius: 8px;
  object-fit: contain;

  @media only screen and (max-width: 600px) {
    width: 129px;
    height: 129px;
  }
`;

export const ThirdBlockLeftThumbTitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.blue};
  margin-top:10px;
  // &:after {
  //   content: " ↗";
  //   white-space: pre;
  //   color: ${({ theme }) => theme.colors.sky};
  // }
`;

export const ThirdBlockLeftThumbSubtitle = styled.div`
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.blue};
`;

export const ThirdBlockLeftThumbSpecialties = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: flex-start;
  ${({ theme }) => theme.fonts.bodysmall};
  color: ${({ theme }) => theme.colors.blue};
  // background-color: pink;
`;

export const ThirdBlockLeftThumbSpecialty = styled.img`
  image-rendering: auto;
  width: 40px;
  height: 40px;
  border-radius: 8px;
  object-fit: contain;
  margin: 0px 4px 8px;

  @media only screen and (max-width: 600px) {
    width: 30px;
    height: 30px;
  }
`;
