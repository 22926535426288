const productsHotel = [
  {
    id: 1,
    name: "Placa Gesso Performa RU Placo",
    pinPos: [339.75210256449697, 288.59020701911345, 249.99627394439938],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 251.66249777068617,
        y: 213.34092667628047,
        z: 45.318180564514435,
      },
      target: {
        x: 252.19316142590066,
        y: 213.66218439430142,
        z: 46.10252077363697,
      },
      posMobile: {
        x: 251.66249777068617,
        y: 213.34092667628047,
        z: 45.318180564514435,
      },
      targetMobile: {
        x: 252.19316142590066,
        y: 213.66218439430142,
        z: 46.10252077363697,
      },
    },
    cameraOut: {
      pos: {
        x: 73.55601941877593,
        y: 230.37399746140562,
        z: 173.29698187456967,
      },
      target: {
        x: 74.43902948941283,
        y: 230.6382355122847,
        z: 173.6848883937485,
      },
    },
  },
  {
    id: 3,
    name: "Placa Gesso Performa Placo",
    pinPos: [-511.24048400582984, 226.3987270332442, -81.75491447373112],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -577.629303299641,
        y: 157.90879490745206,
        z: 120.51458529197673,
      },
      target: {
        x: -577.4933808386044,
        y: 158.19842260908905,
        z: 119.56714617571109,
      },
      posMobile: {
        x: -577.629303299641,
        y: 157.90879490745206,
        z: 120.51458529197673,
      },
      targetMobile: {
        x: -577.4933808386044,
        y: 158.19842260908905,
        z: 119.56714617571109,
      },
    },
    cameraOut: {
      pos: {
        x: 75.27649187748507,
        y: 230.64031401747732,
        z: 174.0423357011165,
      },
      target: {
        x: 74.3456457917629,
        y: 230.6278591407586,
        z: 173.67713652156974,
      },
    },
  },
  {
    id: 6,
    name: "supergraute quartzolit",
    pinPos: [91.87016327545493, 312.8853085584293, -261.09795362580616],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 50.67120128175462,
        y: 244.77910550628053,
        z: -23.63390105916846,
      },
      target: {
        x: 50.694662093399245,
        y: 245.08248003202357,
        z: -24.58648359493487,
      },
      posMobile: {
        x: 50.67120128175462,
        y: 244.77910550628053,
        z: -23.63390105916846,
      },
      targetMobile: {
        x: 50.694662093399245,
        y: 245.08248003202357,
        z: -24.58648359493487,
      },
    },
    cameraOut: {
      pos: {
        x: 73.98055412936682,
        y: 230.4875701428857,
        z: 174.97628178490635,
      },
      target: {
        x: 73.99944287986129,
        y: 230.68050854123385,
        z: 173.99525273867602,
      },
    },
  },
  {
    id: 18,
    name: "minerale m quartzolit",
    pinPos: [-648.8023783370854, 209.056896788348, 367.72755349015944],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -439.0140410794569,
        y: 228.06952781340033,
        z: 395.54667669008325,
      },
      target: {
        x: -440.0101509950464,
        y: 227.9866376891749,
        z: 395.57658092674194,
      },
      posMobile: {
        x: -439.0140410794569,
        y: 228.06952781340033,
        z: 395.54667669008325,
      },
      targetMobile: {
        x: -440.0101509950464,
        y: 227.9866376891749,
        z: 395.57658092674194,
      },
    },
    cameraOut: {
      pos: {
        x: 74.95755197963994,
        y: 230.68037753886708,
        z: 173.71173942205783,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 19,
    name: "Painel Wallfelt Isover",
    pinPos: [-185.83722715777742, 180.82711290852723, -84.73788915515001],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -123.84823759194249,
        y: 134.07214665176377,
        z: 154.8438026204867,
      },
      target: {
        x: -124.24194556597718,
        y: 134.2399483772233,
        z: 153.9400124832788,
      },
      posMobile: {
        x: -123.84823759194249,
        y: 134.07214665176377,
        z: 154.8438026204867,
      },
      targetMobile: {
        x: -124.24194556597718,
        y: 134.2399483772233,
        z: 153.9400124832788,
      },
    },
    cameraOut: {
      pos: {
        x: 74.71592988142058,
        y: 230.8671602109166,
        z: 174.67266404359395,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 33,
    name: "Placa Rigitone 8/15/20 Super Placo",
    pinPos: [-278.44553347362785, 314.686054797774, 19.38258494595911],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -109.17111740770015,
        y: 58.18048052419031,
        z: 113.71067495542488,
      },
      target: {
        x: -109.71561601335189,
        y: 59.0096343749305,
        z: 113.58408443348104,
      },
      posMobile: {
        x: -109.17111740770015,
        y: 58.18048052419031,
        z: 113.71067495542488,
      },
      targetMobile: {
        x: -109.71561601335189,
        y: 59.0096343749305,
        z: 113.58408443348104,
      },
    },
    cameraOut: {
      pos: {
        x: 74.89364827463403,
        y: 230.47033513320233,
        z: 174.39669194966208,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 34,
    name: "Painel Nexalux Horizon 70/30 Sonex",
    pinPos: [-321.09610109823484, 154.33872860011485, 446.89914920543134],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -143.3395029371832,
        y: 157.70103787546276,
        z: 237.53435388953443,
      },
      target: {
        x: -143.84726883785507,
        y: 157.70058240033538,
        z: 238.39584885914093,
      },
      posMobile: {
        x: -143.3395029371832,
        y: 157.70103787546276,
        z: 237.53435388953443,
      },
      targetMobile: {
        x: -143.84726883785507,
        y: 157.70058240033538,
        z: 238.39584885914093,
      },
    },
    cameraOut: {
      pos: {
        x: 74.7857070962235,
        y: 230.87506418929607,
        z: 173.41290684498716,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 39,
    name: "impermeabilizante tecplus top quartzolit",
    pinPos: [185.89087248421163, 28.85783990089091, 309.8175989349081],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 158.67798084228042,
        y: 222.91866380630051,
        z: 136.24740770071855,
      },
      target: {
        x: 158.94536351140576,
        y: 222.15428089227828,
        z: 136.8341148414645,
      },
      posMobile: {
        x: 158.67798084228042,
        y: 222.91866380630051,
        z: 136.24740770071855,
      },
      targetMobile: {
        x: 158.94536351140576,
        y: 222.15428089227828,
        z: 136.8341148414645,
      },
    },
    cameraOut: {
      pos: {
        x: 73.56478781451678,
        y: 231.44620869326388,
        z: 173.52696060970206,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 42,
    name: "sistema de impermeabilização PU flexível quartzolit",
    pinPos: [-635.8906398653496, 68.83276650198887, 326.842533707239],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -423.9716602272958,
        y: 106.61174372287627,
        z: 328.4930084202827,
      },
      target: {
        x: -424.9338769807118,
        y: 106.40002570423903,
        z: 328.6642229057387,
      },
      posMobile: {
        x: -423.9716602272958,
        y: 106.61174372287627,
        z: 328.4930084202827,
      },
      targetMobile: {
        x: -424.9338769807118,
        y: 106.40002570423903,
        z: 328.6642229057387,
      },
    },
    cameraOut: {
      pos: {
        x: 74.9356303221634,
        y: 230.90718357833464,
        z: 173.7297073347708,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 53,
    name: "Placa de Gesso RU Placo",
    pinPos: [253.08487438297342, 335.9444444451892, 136.25995023451623],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 172.64665614633293,
        y: 108.18258153983489,
        z: 287.5683896790035,
      },
      target: {
        x: 172.76893594040095,
        y: 108.97233068961856,
        z: 286.9672707786363,
      },
      posMobile: {
        x: 172.64665614633293,
        y: 108.18258153983489,
        z: 287.5683896790035,
      },
      targetMobile: {
        x: 172.76893594040095,
        y: 108.97233068961856,
        z: 286.9672707786363,
      },
    },
    cameraOut: {
      pos: {
        x: 73.14939702183206,
        y: 230.18447076052138,
        z: 174.17539252514482,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
];

export default productsHotel;
