import React, { useEffect } from "react";
import { Container, TitleClose } from "./style";
import CloseInterna from "./CloseInterna";

const Header = () => {
  return (
    <Container
      onClick={() => {
        window.location.href = process.env.PUBLIC_URL + "/false";
      }}
    >
      {/* <TitleClose>Cidade inteligente</TitleClose> */}
      <CloseInterna />
    </Container>
  );
};

export default Header;
