export const configData = {
  axis: true,
  animationSpeed: 1,
  camPosX: 389.3784394372835,
  camPosY: 258.63605651465696,
  camPosZ: -309.01582029124063,
  near: 0.1,
  far: 900,
  fov: 30,
  zoom: 1,
  focusDistance: 0.092,
  focalLength: 1.0,
  bokehScale: 3.68,
  height: 1080,
  targetVisible: true,
  targetPosX: 2,
  targetPosY: 20,
  targetPosZ: 0,
  minPolarAngle: Math.PI / 4.5,
  maxPolarAngle: Math.PI / 3,
  minDistance: 550,
  maxDistance: 550,
  enableZoom: true,
  enablePan: false,
  enableRotate: true,
  rotateSpeed: 0.5,
  autoRotate: false,
  enableDamping: true,
  dampingFactor: 0.1,
  backgroundColor: "#07b4ec",
  fog: false,
  fogColor: "#07b4ec",
  fogNear: 600,
  fogFar: 850,
  menuOpacity: 0,
};

export const configDataMobile = {
  axis: true,
  animationSpeed: 1,
  camPosX: 420,
  camPosY: 400,
  camPosZ: 420,
  near: 0.1,
  far: 900,
  fov: 30,
  zoom: 1,
  focusDistance: 0.092,
  focalLength: 1.0,
  bokehScale: 3.68,
  height: 1080,
  targetVisible: true,
  targetPosX: -10,
  targetPosY: -10,
  targetPosZ: 0,
  minPolarAngle: Math.PI / 4.5,
  maxPolarAngle: Math.PI / 3,
  minDistance: 400,
  maxDistance: 650,
  enableZoom: true,
  enablePan: false,
  enableRotate: true,
  rotateSpeed: 0.5,
  autoRotate: false,
  enableDamping: true,
  dampingFactor: 0.1,
  backgroundColor: "#07b4ec",
  fog: false,
  fogColor: "#07b4ec",
  fogNear: 600,
  fogFar: 850,
  menuOpacity: 0,
};

export const locationsOut = [
  {
    id: 4,
    name: "Hotel",
    materialId: "Hotel",
    thumb: `/assets/images/hotel.jpg`,
    description: "Descrição do Hotel",
    pinPos: [67, 60, 111.5],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 250.83015119058166,
        y: 277.05496496626574,
        z: -30.961773584343234,
      },
      target: {
        x: -17.94703763919932,
        y: -5.906528277109161,
        z: 178.72247913353877,
      },
      posMobile: {
        x: 350.83015119058166,
        y: 277.05496496626574,
        z: -110.961773584343234,
      },
      targetMobile: {
        x: -17.94703763919932,
        y: 45.906528277109161,
        z: 178.72247913353877,
      },
    },
    cameraOut: {
      pos: {
        x: 359.34204551447453,
        y: 800,
        z: -236.80639784880282,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 3,
    name: "Hospital",
    materialId: "Hospital",
    thumb: `/assets/images/hospital.jpg`,
    description: "Descrição do Hospital",
    pinPos: [40, 25, 62],
    boardPos: [-46, 72, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 196.8283294723,
        y: 247.69324474745406,
        z: -104.37646305621132,
      },
      target: {
        x: -8.792162443718228,
        y: -15.002948536249107,
        z: 116.32904964899052,
      },
      posMobile: {
        x: 296.8283294723,
        y: 247.69324474745406,
        z: -220.37646305621132,
      },
      targetMobile: {
        x: -8.792162443718228,
        y: 25.002948536249107,
        z: 116.32904964899052,
      },
    },
    cameraOut: {
      pos: {
        x: 409.5915888725715,
        y: 800,
        z: 95.79036969117942,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 7,
    name: "Parque Eólico",
    materialId: ["Energia", "Helice"],
    thumb: `/assets/images/parqueeolico.jpg`,
    description: "Descrição Energia Eólica",
    pinPos: [50, 90, -148.5],
    boardPos: [-39, 60, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 199.48385433775803,
        y: 356.8620281745439,
        z: -357.3206815456765,
      },
      target: {
        x: 1.4700660028794623,
        y: 24.708187017543388,
        z: -74.50111778876331,
      },
      posMobile: {
        x: 209.59537904691643,
        y: 220.1386314516232,
        z: -558.089805765027,
      },
      targetMobile: {
        x: -7.319350602121656,
        y: 74.2936373554452,
        z: 0,
      },
    },
    cameraOut: {
      pos: {
        x: 322.8775816760988,
        y: 800,
        z: 319.39101969793995,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 1,
    name: "Edifício Residencial",
    materialId: "EdComercial",
    thumb: `/assets/images/edres.jpg`,
    description: "Descrição do Prédio",
    pinPos: [-74.5, 90, 51],
    boardPos: [-37, 55, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -196.17572740633753,
        y: 323.6180716807185,
        z: -118.08943159569947,
      },
      target: {
        x: 10.586692306986576,
        y: 22.860789448637826,
        z: 142.9041668563675,
      },
      posMobile: {
        x: -326.17572740633753,
        y: 323.6180716807185,
        z: -285.08943159569947,
      },
      targetMobile: {
        x: -5.586692306986576,
        y: 65.860789448637826,
        z: 142.9041668563675,
      },
    },
    cameraOut: {
      pos: {
        x: -232.10465932628063,
        y: 800,
        z: 423.2362039106099,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 9,
    name: "Galpão",
    materialId: "Galpao",
    thumb: `/assets/images/galpao.jpg`,
    description: "Descrição do Galpao",
    pinPos: [-53, 20, -53],
    boardPos: [-48, 78, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -222.15186428861938,
        y: 243.99205079342073,
        z: 119.35086058348371,
      },
      target: {
        x: -1.0107200960154679,
        y: -1.326583607577808,
        z: -106.29346564878647,
      },
      posMobile: {
        x: -322.15186428861938,
        y: 243.99205079342073,
        z: 225.35086058348371,
      },
      targetMobile: {
        x: -1.0107200960154679,
        y: 20.326583607577808,
        z: -106.29346564878647,
      },
    },
    cameraOut: {
      pos: {
        x: -368.13871153653344,
        y: 800,
        z: 260.94900172100625,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 8,
    name: "Escola",
    materialId: "Escola",
    thumb: `/assets/images/escola.jpg`,
    description: "Descrição da Escola",
    pinPos: [-72, 25, 0],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -243.39490455224063,
        y: 213.47189671724072,
        z: 183.2152708234283,
      },
      target: {
        x: -15.572147290330356,
        y: -7.329364222358532,
        z: -60.390136425802744,
      },
      posMobile: {
        x: -343.39490455224063,
        y: 213.47189671724072,
        z: 290.2152708234283,
      },
      targetMobile: {
        x: -15.572147290330356,
        y: 25.329364222358532,
        z: -60.390136425802744,
      },
    },
    cameraOut: {
      pos: {
        x: -367.72637319839043,
        y: 800,
        z: 260.6567145003765,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 10,
    name: "Residencial Unifamiliar",
    materialId: "Casa",
    thumb: `/assets/images/casa.jpg`,
    description: "Descrição da Casa",
    pinPos: [-30, 20, 3],
    boardPos: [-46, 76, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 150.48952324663819,
        y: 264.7091757944077,
        z: 142.84740891775405,
      },
      target: {
        x: -78.20888730056814,
        y: -13.93912188500148,
        z: -30.508188665793334,
      },
      posMobile: {
        x: 250.48952324663819,
        y: 264.7091757944077,
        z: 200.84740891775405,
      },
      targetMobile: {
        x: -78.20888730056814,
        y: 30.93912188500148,
        z: -30.508188665793334,
      },
    },
    cameraOut: {
      pos: {
        x: 384.7376734644313,
        y: 800,
        z: 153.8338769708527,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 11,
    name: "Shopping",
    materialId: "Shopping",
    thumb: `/assets/images/shopping.jpg`,
    description: "Descrição do Shopping",
    pinPos: [48, 30, 3],
    boardPos: [-46, 70, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 243.69179926601998,
        y: 240.52052964520232,
        z: 151.62262191242462,
      },
      target: {
        x: -13.782475299567189,
        y: -6.285279567438856,
        z: -46.295447950633545,
      },
      posMobile: {
        x: 343.69179926601998,
        y: 240.52052964520232,
        z: 239.62262191242462,
      },
      targetMobile: {
        x: -13.782475299567189,
        y: 30.285279567438856,
        z: -46.295447950633545,
      },
    },
    cameraOut: {
      pos: {
        x: 469.5791897838509,
        y: 800,
        z: 81.5428282599478,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 2,
    name: "Escritório",
    materialId: "Escritorio",
    thumb: `/assets/images/escritorio.jpg`,
    description: "Descrição do Escritório",
    pinPos: [67, 24, -47],
    boardPos: [-46, 74, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 290.3942019812768,
        y: 213.21694329538755,
        z: 54.42186507543899,
      },
      target: {
        x: -16.990594722203664,
        y: -2.876745478836506,
        z: -82.75723109999423,
      },
      posMobile: {
        x: 420.3942019812768,
        y: 213.21694329538755,
        z: 100.42186507543899,
      },
      targetMobile: {
        x: -16.990594722203664,
        y: 20.876745478836506,
        z: -82.75723109999423,
      },
    },
    cameraOut: {
      pos: {
        x: 411.0198552005528,
        y: 800,
        z: -125.91181080290448,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 5,
    name: "Indústria",
    materialId: "Industria",
    thumb: `/assets/images/industria.jpg`,
    description: "Descrição da Ind",
    pinPos: [-45, 24, -127],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -231.31967510390098,
        y: 249.13043185379846,
        z: 13.878567054585545,
      },
      target: {
        x: 13.884145655978493,
        y: -10.95252368083359,
        z: -171.61918122028013,
      },
      posMobile: {
        x: -331.31967510390098,
        y: 249.13043185379846,
        z: 93.878567054585545,
      },
      targetMobile: {
        x: 13.884145655978493,
        y: 30.95252368083359,
        z: -171.61918122028013,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
  {
    id: 6,
    name: "Infraestrutura",
    materialId: "Industria",
    thumb: `/assets/images/tunel.jpg`,
    description: "Descrição da Infraestrutura",
    pinPos: [-22, 24, -93],
    boardPos: [-48, 77, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -231.31967510390098,
        y: 249.13043185379846,
        z: 13.878567054585545,
      },
      target: {
        x: 13.884145655978493,
        y: -10.95252368083359,
        z: -171.61918122028013,
      },
      posMobile: {
        x: -201.31967510390098,
        y: 249.13043185379846,
        z: 93.878567054585545,
      },
      targetMobile: {
        x: 35.884145655978493,
        y: 20.95252368083359,
        z: -151.61918122028013,
      },
    },
    cameraOut: {
      pos: {
        x: -423.1948067758375,
        y: 800,
        z: -225.241935672166,
      },
      target: [0, 20, 0],
    },
    products: [],
  },
];
