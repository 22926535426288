import styled from "styled-components";

export const MenuHolder = styled.div`
  pointer-events: all;
  position: absolute;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  width: 60px;
  height: 60px;
  top: 30px;
  right: 40px;
  background-color: ${({ theme }) => theme.colors.blue};
  border-radius: 50%;
  
  &.mobile {
    width: 40px;
    height: 40px;
    top: 30px;
    right: 30px;
    left: unset;
    bottom: unset;
    background-color: ${({ theme }) => theme.colors.blue};
  }
`;

export const Menu = styled.input.attrs({ type: "checkbox" })`
  pointer-events: none;
  position: absolute;
  width: 30px;
  height: 30px;
  // top: calc((70px - 20px) / 2);
  z-index: 100;
  opacity: 0;
  z-index: 10;

  background-color: #4caf50;

  & + label {
    div:first-child {
      top: -4px;
      right: -4px;
      transform: rotateZ(45deg);
    }
    div:last-child {
      top: 5px;
      right: -1px;
      transform: rotateZ(45deg);
    }
    div:nth-child(2) {
      width: 37px;
      top: 0px;
      right: 1px;
      transform: rotateZ(-45deg);
    }
  }
`;

export const MenuLines = styled.label`
  position: absolute;
  display: block;
  width: 30px;
  height: 20px;
  z-index: 1;
  // top: calc((70px - 20px) / 2);
  cursor: pointer;
  transform: scale(0.7) translateX(-2px);
 

  &.mobile {
    transform: scale(0.55) translateX(-3px);
  } 

  &:before {
    content: "";
    transition: 1.2s cubic-bezier(0, 0.96, 1, 0.02) background-color;
  }
`;

export const MenuLine = styled.div`
  position: relative;
  top: 0;
  height: 2px;
  background-color: #ffffff;
  margin-bottom: 7px;
  transition: 0.3s ease transform, 0.3s ease top, 0.3s ease width,
    0.3s ease right;
  border-radius: 2px;

  &:first-child {
    transform-origin: 0px;
  }

  &:last-child {
    margin-bottom: 0;
    transform-origin: 30px;
  }

  &:nth-child(2) {
    right: 0px;
    width: 30px;
  }
`;
