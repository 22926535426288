const productsInfraestrutura = [
  {
    id: 11,
    name: "verniz FS quartzolit",
    pinPos: [-1135.2394896292624, 496.7244069813876, -951.5891382015234],
    scale: 3,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -826.9048091993843,
        y: 285.35707136019386,
        z: -259.51725689182837,
      },
      target: {
        x: -827.4336509935953,
        y: 285.5935524486746,
        z: -260.33236611854994,
      },
      posMobile: {
        x: -826.9048091993843,
        y: 285.35707136019386,
        z: -259.51725689182837,
      },
      targetMobile: {
        x: -827.4336509935953,
        y: 285.5935524486746,
        z: -260.33236611854994,
      },
    },
    cameraOut: {
      pos: {
        x: 92.1999054333191,
        y: 281.23636441913743,
        z: 192.29263183269555,
      },
      target: {
        x: 91.46402199409837,
        y: 281.36687230308087,
        z: 191.62821987004793,
      },
    },
  },
  {
    id: 13,
    name: "graute pavi quartzolit",
    pinPos: [-1146.9223024756398, -22.262028424712405, -259.8860701737094],
    scale: 3,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -405.83030290262525,
        y: 298.6894373886644,
        z: -492.6466460443972,
      },
      target: {
        x: -406.65320469793477,
        y: 298.3116087927876,
        z: -492.2222898409382,
      },
      posMobile: {
        x: -405.83030290262525,
        y: 298.6894373886644,
        z: -492.6466460443972,
      },
      targetMobile: {
        x: -406.65320469793477,
        y: 298.3116087927876,
        z: -492.2222898409382,
      },
    },
    cameraOut: {
      pos: {
        x: 92.38207925321967,
        y: 281.6017481282914,
        z: 191.9476008583775,
      },
      target: {
        x: 91.46402199409837,
        y: 281.36687230308087,
        z: 191.62821987004793,
      },
    },
  },
  {
    id: 49,
    name: "contrapiso autonivelante RAD quartzolit",
    pinPos: [-452.3400175721979, 333.86439739928585, 807.1418239705197],
    scale: 2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -263.91412223621376,
        y: 435.8039509416557,
        z: 319.31711512878854,
      },
      target: {
        x: -264.09996422210145,
        y: 435.54999806686016,
        z: 320.26630988944856,
      },
      posMobile: {
        x: -263.91412223621376,
        y: 435.8039509416557,
        z: 319.31711512878854,
      },
      targetMobile: {
        x: -264.09996422210145,
        y: 435.54999806686016,
        z: 320.26630988944856,
      },
    },
    cameraOut: {
      pos: {
        x: 92.1148287357758,
        y: 281.31761362350966,
        z: 190.87057604378515,
      },
      target: {
        x: 91.46402199409837,
        y: 281.36687230308087,
        z: 191.62821987004793,
      },
    },
  },
  {
    id: 51,
    name: "Injeção PU espuma",
    pinPos: [-888.5313072281103, 685.0953198290458, 757.6803602083069],
    scale: 3,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -760.1222435139687,
        y: 117.9570786617149,
        z: 48.22178279466097,
      },
      target: {
        x: -760.0962604838533,
        y: 118.59694381284983,
        z: 48.98983062553422,
      },
      posMobile: {
        x: -760.1222435139687,
        y: 117.9570786617149,
        z: 48.22178279466097,
      },
      targetMobile: {
        x: -760.0962604838533,
        y: 118.59694381284983,
        z: 48.98983062553422,
      },
    },
    cameraOut: {
      pos: {
        x: 92.26355011308397,
        y: 281.0166466269177,
        z: 191.1402682713136,
      },
      target: {
        x: 91.46402199409837,
        y: 281.36687230308087,
        z: 191.62821987004793,
      },
    },
  },
  {
    id: 55,
    name: "Pintura asfáltica quartzolit",
    pinPos: [-502.5529840432514, -30.05891140544117, 481.9419552066441],
    scale: 2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -409.439216843102,
        y: 336.47582011326904,
        z: 174.7371313509449,
      },
      target: {
        x: -409.46983294640194,
        y: 335.71444896154264,
        z: 175.38472429681255,
      },
      posMobile: {
        x: -409.439216843102,
        y: 336.47582011326904,
        z: 174.7371313509449,
      },
      targetMobile: {
        x: -409.46983294640194,
        y: 335.71444896154264,
        z: 175.38472429681255,
      },
    },
    cameraOut: {
      pos: {
        x: 92.2554277313858,
        y: 281.7693437502796,
        z: 191.16811804090753,
      },
      target: {
        x: 91.46402199409837,
        y: 281.36687230308087,
        z: 191.62821987004793,
      },
    },
  },
];

export default productsInfraestrutura;
