import { ArrowRight } from "../../assets/images";
const productsResidenciaUnifamiliar = [
  {
    id: 0,
    name: "Sair",
    environmentArea: 0,
    category: { image: ArrowRight },
    pinPos: [753.6705505013035, 179.4331464232611, -564.0816180280942],
    scale: 0.7,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 522.0997932360405,
        y: 234.3866482659554,
        z: -587.3078398946157,
      },
      target: {
        x: 521.5026932288724,
        y: 234.08996335939108,
        z: -586.5625549506802,
      },
      posMobile: {
        x: 522.0997932360405,
        y: 234.3866482659554,
        z: -587.3078398946157,
      },
      targetMobile: {
        x: 521.5026932288724,
        y: 234.08996335939108,
        z: -586.5625549506802,
      },
    },
    cameraOut: {
      pos: {
        x: 522.0997932360405,
        y: 234.3866482659554,
        z: -587.3078398946157,
      },
      target: {
        x: 521.5026932288724,
        y: 234.08996335939108,
        z: -586.5625549506802,
      },
    },
  },
  {
    id: -1,
    name: "Entrar",
    environmentArea: 0,
    category: { image: ArrowRight },
    pinPos: [507.506079640183, 163.87407055940196, -1000.5287797419821],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 804.968183077821,
        y: 220.65497811340387,
        z: -788.6655126395825,
      },
      target: {
        x: 805.504471746988,
        y: 220.49317855486163,
        z: -787.8371314675496,
      },
      posMobile: {
        x: 804.968183077821,
        y: 220.65497811340387,
        z: -788.6655126395825,
      },
      targetMobile: {
        x: 805.504471746988,
        y: 220.49317855486163,
        z: -787.8371314675496,
      },
    },
    cameraOut: {
      pos: {
        x: 804.968183077821,
        y: 220.65497811340387,
        z: -788.6655126395825,
      },
      target: {
        x: 805.504471746988,
        y: 220.49317855486163,
        z: -787.8371314675496,
      },
    },
  },
  {
    id: 3,
    name: "Placa Gesso Performa Placo",
    environmentArea: 0,
    pinPos: [1782.726002584705, 136.3210004286938, -2.704626225245647],
    scale: 2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 1592.575040413391,
        y: 238.2076119593563,
        z: -513.279812545712,
      },
      target: {
        x: 1593.0602951725962,
        y: 238.034327793943,
        z: -512.4227825481707,
      },
      posMobile: {
        x: 1592.575040413391,
        y: 238.2076119593563,
        z: -513.279812545712,
      },
      targetMobile: {
        x: 1593.0602951725962,
        y: 238.034327793943,
        z: -512.4227825481707,
      },
    },
    cameraOut: {
      pos: {
        x: 803.7488666011192,
        y: 220.7009163054548,
        z: -787.7408007083931,
      },
      target: {
        x: 804.5441504296808,
        y: 220.53915461304956,
        z: -787.1565432421177,
      },
    },
  },
  {
    id: 19,
    name: "Painel Wallfelt Isover",
    environmentArea: 0,
    pinPos: [1756.9862178081544, 120.5704424492795, -778.626524049445],
    scale: 1.6,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 1264.139754083573,
        y: 212.85216949098725,
        z: -557.5056589786625,
      },
      target: {
        x: 1264.9564033719578,
        y: 212.66091526348444,
        z: -558.0501824035426,
      },
      posMobile: {
        x: 1264.139754083573,
        y: 212.85216949098725,
        z: -557.5056589786625,
      },
      targetMobile: {
        x: 1264.9564033719578,
        y: 212.66091526348444,
        z: -558.0501824035426,
      },
    },
    cameraOut: {
      pos: {
        x: 803.5531489981726,
        y: 220.6621485439429,
        z: -787.103735518137,
      },
      target: {
        x: 804.5441504296808,
        y: 220.53915461304956,
        z: -787.1565432421177,
      },
    },
  },
  {
    id: 25,
    name: "Sistema Nextera - Glasroc X",
    pinPos: [464.8967260402007, 252.3126373063985, 560.2214334804612],
    scale: 1.6,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 556.7681099277355,
        y: 229.15243082086613,
        z: 303.2868915884829,
      },
      target: {
        x: 556.599142456425,
        y: 229.26205330904736,
        z: 304.26639804773873,
      },
      posMobile: {
        x: 556.7681099277355,
        y: 229.15243082086613,
        z: 303.2868915884829,
      },
      targetMobile: {
        x: 556.599142456425,
        y: 229.26205330904736,
        z: 304.26639804773873,
      },
    },
    cameraOut: {
      pos: {
        x: 521.4894708673932,
        y: 234.0605999427434,
        z: -587.5620362956386,
      },
      target: {
        x: 521.5026932288724,
        y: 234.08996335939108,
        z: -586.5625549506802,
      },
    },
  },
  {
    id: 29,
    name: "Nexalux Vert 90/65 Sonex",
    environmentArea: 0,
    pinPos: [982.3583051834285, 343.0802044308115, 469.33775848951126],
    scale: 2,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 747.7599848603622,
        y: 174.36391087640132,
        z: -47.81221730258603,
      },
      target: {
        x: 748.3024323977543,
        y: 174.60561610440894,
        z: -47.00764956029764,
      },
      posMobile: {
        x: 747.7599848603622,
        y: 174.36391087640132,
        z: -47.81221730258603,
      },
      targetMobile: {
        x: 748.3024323977543,
        y: 174.60561610440894,
        z: -47.00764956029764,
      },
    },
    cameraOut: {
      pos: {
        x: 804.4356923375241,
        y: 220.43384645231635,
        z: -788.145050719848,
      },
      target: {
        x: 804.5441504296808,
        y: 220.53915461304956,
        z: -787.1565432421177,
      },
    },
  },
  {
    id: 41,
    name: "impermeabilizante tecplus lastic quartzolit",
    pinPos: [399.60711617621166, 22.359536457026095, -203.77269784954933],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 468.08082977195664,
        y: 224.37150006381924,
        z: -362.37614989829115,
      },
      target: {
        x: 467.9866722227618,
        y: 223.60545562070027,
        z: -361.7402959314905,
      },
      posMobile: {
        x: 468.08082977195664,
        y: 224.37150006381924,
        z: -362.37614989829115,
      },
      targetMobile: {
        x: 467.9866722227618,
        y: 223.60545562070027,
        z: -361.7402959314905,
      },
    },
    cameraOut: {
      pos: {
        x: 521.7296882465618,
        y: 234.60609824409914,
        z: -587.3884351131321,
      },
      target: {
        x: 521.5026932288724,
        y: 234.08996335939108,
        z: -586.5625549506802,
      },
    },
  },
  {
    id: 49,
    name: "contrapiso autonivelante RAD quartzolit",
    environmentArea: 0,
    pinPos: [1104.632452581648, 35.00988387080626, 73.23763783129317],
    scale: 1.5,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 828.6295353200674,
        y: 135.68875293908604,
        z: -144.8745322643788,
      },
      target: {
        x: 829.4699168799643,
        y: 135.39243566746833,
        z: -144.42070971279492,
      },
      posMobile: {
        x: 828.6295353200674,
        y: 135.68875293908604,
        z: -144.8745322643788,
      },
      targetMobile: {
        x: 829.4699168799643,
        y: 135.39243566746833,
        z: -144.42070971279492,
      },
    },
    cameraOut: {
      pos: {
        x: 804.1879957697084,
        y: 220.7936344240063,
        z: -788.0556505126896,
      },
      target: {
        x: 804.5441504296808,
        y: 220.53915461304956,
        z: -787.1565432421177,
      },
    },
  },
  {
    id: 56,
    name: "Sistema PU Piscinas e Reservatórios",
    pinPos: [-212.63377458530454, -77.40891804648791, -277.90911431006043],
    scale: 1,
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -134.7139834492314,
        y: 43.226622885999646,
        z: -482.59307202644,
      },
      target: {
        x: -134.87439125523744,
        y: 42.72315564559834,
        z: -481.74407846132004,
      },
      posMobile: {
        x: -134.7139834492314,
        y: 43.226622885999646,
        z: -482.59307202644,
      },
      targetMobile: {
        x: -134.87439125523744,
        y: 42.72315564559834,
        z: -481.74407846132004,
      },
    },
    cameraOut: {
      pos: {
        x: 522.3372485587911,
        y: 234.44948671293852,
        z: -586.9799999884497,
      },
      target: {
        x: 521.5026932288724,
        y: 234.08996335939108,
        z: -586.5625549506802,
      },
    },
  },
];

export default productsResidenciaUnifamiliar;
