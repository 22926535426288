import { useRef, useEffect, useState } from "react";
import { isMobile } from "react-device-detect";
import * as THREE from "three";
import { invalidate, useLoader, useThree } from "@react-three/fiber";
import {
  Circle,
  Backdrop,
  Billboard,
  Image,
  Text,
  Html,
} from "@react-three/drei";
import { LayerMaterial, Depth } from "lamina";

import plusTexture from "../../../../assets/images/plus.png";
import plusDesaturatedTexture from "../../../../assets/images/plus-desaturated.png";
import { TextContainer, Title, Description } from "./styled.js";

const BillboardMenu = ({
  follow,
  lockX,
  lockY,
  lockZ,
  setPlaceHighlighted,
  location,
  setLocation,
  tabMenu,
  setTabMenu,
  setIsAnimating,
  setData,
  locationProps,
  refOrbit,
  locationData,
  persona,
}) => {
  const { camera } = useThree();
  const plusMap = useLoader(THREE.TextureLoader, plusTexture);
  const plusDesaturatedMap = useLoader(
    THREE.TextureLoader,
    plusDesaturatedTexture
  );
  const ref = useRef();
  const clone = require("rfdc")();
  const [isOver, setIsOver] = useState(false);
  const [localLocationProps, setLocalLocationProps] = useState(locationProps);
  let size = 1;
  let emissive = "purple";
  let glow = "#90a5ff";
  let scale = size * 8;
  let near = -10;
  let far = 1.2;
  let color = glow || emissive || "white";

  const clicked = (e, index) => {
    // console.log(locationProps);
    e.stopPropagation();
    let locationProp = localLocationProps.find((prop) => prop.id === index);
    setPlaceHighlighted(locationProp.materialId);
    setLocation(index);
    let newTabMenu = clone(tabMenu);
    newTabMenu.map((tab, i) => {
      tab.items.map((item, o) => {
        if (i === 0 && item.id === index) {
          item.selected = true;
          return item;
        } else {
          item.selected = false;
          return item;
        }
      });
    });
    setTabMenu(newTabMenu);
    setData((values) => ({
      ...values,
      camPosX: isMobile
        ? locationProp.cameraIn.posMobile.x
        : locationProp.cameraIn.pos.x,
      camPosY: isMobile
        ? locationProp.cameraIn.posMobile.y
        : locationProp.cameraIn.pos.y,
      camPosZ: isMobile
        ? locationProp.cameraIn.posMobile.z
        : locationProp.cameraIn.pos.z,
      targetPosX: isMobile
        ? locationProp.cameraIn.targetMobile.x
        : locationProp.cameraIn.target.x,
      targetPosY: isMobile
        ? locationProp.cameraIn.targetMobile.y
        : locationProp.cameraIn.target.y,
      targetPosZ: isMobile
        ? locationProp.cameraIn.targetMobile.z
        : locationProp.cameraIn.target.z,
      animationSpeed: locationProp.animationSpeed,
    }));
    setIsAnimating(true);
    refOrbit.current.enableRotate = false;
    refOrbit.current.enableZoom = false;
  };

  const onOver = (e, index) => {
    // console.log(localLocationProps);
    e.stopPropagation();
    if (location === null) {
      let locationProp = localLocationProps.find((prop) => prop.id === index);
      setPlaceHighlighted(locationProp.materialId);
      setIsAnimating(false);
    }
  };

  const onOut = (e, index) => {
    // console.log(localLocationProps);
    e.stopPropagation();
    if (location === null) {
      let locationProp = localLocationProps.find((prop) => prop.id === index);
      setPlaceHighlighted(null);
      setIsAnimating(false);
    }
  };

  const getIn = (e, index) => {
    e.stopPropagation();
    let locationProp = localLocationProps.find((prop) => prop.id === index);
    if (locationProp.products.length > 0) {
      let routeName = locationProp.name
        .trim()
        .toLowerCase()
        .replace(/\s/g, "");
      routeName = routeName.normalize("NFD").replace(/\p{Diacritic}/gu, "");
      window.location.href = process.env.PUBLIC_URL + "/in/" + routeName;
    }
  };

  useEffect(() => {
    setLocalLocationProps(locationProps);
  }, [locationProps]);

  useEffect(() => {
    console.log(localLocationProps);
  }, [localLocationProps]);

  return (
    <>
      {localLocationProps.map((locationProp, index) => {
        return (
          <Billboard
            key={index}
            follow={follow}
            lockX={lockX}
            lockY={lockY}
            lockZ={lockZ}
            position={locationProp.pinPos}
          >
            <Circle
              args={[10, 20]}
              onPointerEnter={(e) => {
                onOver(e, locationProp.id);
              }}
              onPointerLeave={(e) => {
                onOut(e, locationProp.id);
              }}
              onClick={(e) => {
                clicked(e, locationProp.id);
              }}
              renderOrder={10}
            >
              <meshBasicMaterial
                map={
                  persona
                    .find((element) => element.selected)
                    .environments.find(
                      (element2) => element2.id === locationProp.id
                    )
                    ? plusMap
                    : plusDesaturatedMap
                }
                transparent
                depthTest={true}
                depthWrite={true}
              />
            </Circle>

            {location === locationProp.id && (
              <Html
                ref={ref}
                style={{
                  userSelect: "none",
                  width: "506px",
                  height: "387px",
                  backgroundColor: "white",
                  borderRadius: "10px",
                  zIndex: "0",
                }}
                scale={7}
                position={[0, 40, 0]}
                transform
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyComtemt: "center",
                    userSelect: "none",
                    width: "506px",
                    height: "236px",
                    borderTopLeftRadius: "10px",
                    borderTopRightRadius: "10px",
                    overflow: "hidden",
                    zIndex: "0",
                  }}
                  onPointerDown={(e) => getIn(e, location)}
                >
                  <img
                    src={process.env.PUBLIC_URL + locationProp.thumb}
                    width="506"
                    style={{ pointerEvents: "none" }}
                  />
                </div>
                <TextContainer onPointerDown={(e) => getIn(e, index)}>
                  <Title>
                    {locationProp.name} {` `}
                  </Title>
                  <Description>{locationProp.description}</Description>
                </TextContainer>
              </Html>
            )}
          </Billboard>
        );
      })}
    </>
  );
};

export default BillboardMenu;
