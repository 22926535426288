import { ThemeProvider } from "styled-components";

export const theme = {
  colors: {
    black: "#000000",
    white: "#ffffff",
    offwhite: "#FFF9F4",
    offwhite32: "rgba(255, 249, 244, 0.32)",
    darkblue: "#2F3D64",
    grey: "#aaaaaa",
    greyDark: "#777777",
    greylight: "#899DCE",
    greylightest: "#CFD1DB",
    sky: "#0476d2",
    yellow: "#fff200",
    blue: "#283891",
  },
  fonts: {
    assinatura: {
      "font-family": "Caveat",
      "font-style": "normal",
      "font-weight": "800",
      "font-size": "32px",
      "line-height": "36px",
    },
    body: {
      "font-family": "GothamBook",
      "font-style": "normal",
      "font-weight": "400",
      "font-size": "16px",
      "line-height": "36px",
    },
    bodytitle: {
      "font-family": "GothamBold",
      "font-style": "normal",
      "font-weight": "800",
      "font-size": "16px",
      "line-height": "36px",
    },
    bodysmall: {
      "font-family": "GothamBook",
      "font-style": "normal",
      "font-weight": "400",
      "font-size": "14px",
      "line-height": "22px",
    },
    bodysmallLink: {
      "font-family": "GothamBold",
      "font-style": "normal",
      "font-weight": "800",
      "font-size": "14px",
      "line-height": "32px",
      "text-decoration": "none",
    },
    display: {
      "font-family": "GothamBold",
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "64px",
      "line-height": "82px",
      "letter-spacing": "0.02em",
      "text-transform": "uppercase",
    },
    displaysmall: {
      "font-family": "GothamMedium",
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "42px",
      "line-height": "53.59px",
      "letter-spacing": "0.16em",
      "text-transform": "uppercase",
    },
    h1: {
      "font-family": "GothamBold",
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "48px",
      "line-height": "61px",
    },
    h2: {
      "font-family": "GothamBold",
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "32px",
      "line-height": "41px",
    },
    h3: {
      "font-family": "GothamBold",
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "24px",
      "line-height": "31px",
      "text-transform": "uppercase",
    },
    h4: {
      "font-family": "GothamBold",
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "23px",
    },
    caption: {
      "font-family": "GothamMedium",
      "font-style": "normal",
      "font-weight": "500",
      "font-size": "18px",
      "line-height": "20px",
      "letter-spacing": "0px",
    },
  },
};

const Theme = ({ children }) => (
  <ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
