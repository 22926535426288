import { useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame, useThree, invalidate } from "@react-three/fiber";

const lerp = (start, end, amt) => {
  return (1 - amt) * start + amt * end;
};

const AnimateCamera = ({
  data,
  isAnimating,
  setIsAnimating,
  refOrbit,
  secondaryMenuOn,
  setSecondaryMenuOn,
}) => {
  const { camera, performance } = useThree();

  useEffect(() => {
    // performance.debounce = 150;
    // performance.debounce = 150;
    refOrbit.current.object.position.equals = (v, epsilon = Number.EPSILON) => {
      return (
        Math.abs(v.x - refOrbit.current.object.position.x) < epsilon &&
        Math.abs(v.y - refOrbit.current.object.position.y) < epsilon &&
        Math.abs(v.z - refOrbit.current.object.position.z) < epsilon
      );
    };

    refOrbit.current.target.equals = (v, epsilon = Number.EPSILON) => {
      return (
        Math.abs(v.x - refOrbit.current.target.x) < epsilon &&
        Math.abs(v.y - refOrbit.current.target.y) < epsilon &&
        Math.abs(v.z - refOrbit.current.target.z) < epsilon
      );
    };
  }, []);

  useFrame(() => {
    if (isAnimating) {
      // console.log(isAnimating);

      if (secondaryMenuOn) {
        if (
          !refOrbit.current.object.position.equals(
            new THREE.Vector3(150, 500, -40),
            0.1
          ) &&
          !refOrbit.current.target.equals(new THREE.Vector3(130, 0, 80), 0.1)
        ) {
          refOrbit.current.object.position.lerp(
            new THREE.Vector3(150, 500, -40),
            data.animationSpeed
          );
          refOrbit.current.target.lerp(
            new THREE.Vector3(130, 0, 80),
            data.animationSpeed
          );
        }
      } else {
        if (
          !refOrbit.current.object.position.equals(
            new THREE.Vector3(data.camPosX, data.camPosY, data.camPosZ),
            0.1
          ) &&
          !refOrbit.current.target.equals(
            new THREE.Vector3(
              data.targetPosX,
              data.targetPosY,
              data.targetPosZ
            ),
            0.1
          )
        ) {
          refOrbit.current.object.position.lerp(
            new THREE.Vector3(data.camPosX, data.camPosY, data.camPosZ),
            data.animationSpeed
          );
          refOrbit.current.target.lerp(
            new THREE.Vector3(
              data.targetPosX,
              data.targetPosY,
              data.targetPosZ
            ),
            data.animationSpeed
          );
        }
      }
      // refOrbit.current.maxPolarAngle = lerp(
      //   refOrbit.current.maxPolarAngle,
      //   data.maxPolarAngle,
      //   data.animationSpeed
      // );
      refOrbit.current.update();
    }
  });
};

export default AnimateCamera;
