import { useState } from "react";
import { isMobile } from "react-device-detect";
import { useNavigate, useParams } from "react-router-dom";
import dompurify from "dompurify";
import { Offcanvas } from "react-bootstrap";
import { useScrollContainer } from "react-indiana-drag-scroll";
import { useTheme } from "styled-components";
import CloseInterna from "../Header/CloseInterna";
import {
  AllBlocks,
  LeftBlock,
  RightBlock,
  FirstBlock,
  FirstBlockTop,
  FirstBlockTopRight,
  FirstBlockImage,
  FirstBlockLogo,
  FirstBlockTopRightText,
  FirstBlockTitle,
  FirstBlockText,
  FirstBlockThumbs,
  FirstBlockThumb,
  SecondBlock,
  ThirdBlock,
  FirstBlockRight,
  FirstBlockRightTitle,
  FirstBlockRightThumbs,
  FirstBlockRightThumbContainer,
  FirstBlockRightThumb,
  FirstBlockRightThumbTitle,
  ThirdBlockLeftTitle,
  ThirdBlockLeftThumbs,
  ThirdBlockLeftThumbContainer,
  ThirdBlockLeftThumb,
  ThirdBlockLeftThumbTitle,
  ThirdBlockLeftThumbSubtitle,
  ThirdBlockLeftThumbSpecialties,
  ThirdBlockLeftThumbSpecialty,
} from "./styled";
import { useEffect } from "react";

const SectionContent = ({
  setIsAnimating,
  setData,
  locationProps,
  location,
  setLocation,
  isInsideSection,
  setIsInsideSection,
  environment,
  refOrbit,
  local,
}) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const scrollContainer = useScrollContainer();
  const scrollContainer2 = useScrollContainer();
  const scrollContainer3 = useScrollContainer();
  const [selectedImage, setSelectedImage] = useState(
    "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
  );
  const [updateImage, setUpdateImage] = useState(false);
  const [indexImage, setIndexImage] = useState(0);

  const sanitizer = dompurify.sanitize;

  const closeInterna = () => {
    setIsInsideSection(false);
    if (location !== null) {
      refOrbit.current.enableRotate = true;
      refOrbit.current.enableZoom = true;
      setData((values) => ({
        ...values,
        entryPos: {
          ...values.entryPos,
          [local]: {
            camPosX: locationProps[location].cameraOut.pos.x,
            camPosY: locationProps[location].cameraOut.pos.y,
            camPosZ: locationProps[location].cameraOut.pos.z,
            targetPosX: locationProps[location].cameraOut.target.x,
            targetPosY: locationProps[location].cameraOut.target.y,
            targetPosZ: locationProps[location].cameraOut.target.z,
            animationSpeed: locationProps[location].animationSpeed,
          },
        },
      }));
      setIsAnimating(true);
      setLocation(null);
      setSelectedImage(
        "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
      );
    }
    navigate(`/in/${local}`);
  };

  const changeImage = (index) => {
    setIndexImage(index);
  };

  useEffect(() => {
    console.log("environment", environment);
    if (location !== null) {
      if (
        environment &&
        environment.products &&
        environment.products[location].gallery &&
        environment.products[location].gallery.length > 0
      ) {
        if (
          environment.products[location].gallery[indexImage] &&
          environment.products[location].gallery[indexImage].type === "image"
        ) {
          setSelectedImage(
            encodeURI(environment.products[location].gallery[indexImage].url)
          );
        }
      } else {
        setSelectedImage(
          "data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=="
        );
      }
    }
  }, [environment, location, indexImage]);

  return (
    <Offcanvas
      className="offCanvasInternaSection"
      show={isInsideSection}
      placement={isMobile ? "bottom" : "end"}
      backdrop={false}
      style={{ height: "100vh" }}
    >
      <Offcanvas.Body>
        <AllBlocks>
          <LeftBlock>
            <FirstBlock>
              <CloseInterna
                onClick={() => closeInterna()}
                className={"interna"}
              />
              <FirstBlockTop>
                <FirstBlockImage>
                  {selectedImage && (
                    <img
                      src={selectedImage}
                      width="100%"
                      height="100%"
                      style={{ objectFit: "contain" }}
                    />
                  )}
                </FirstBlockImage>
                <FirstBlockTopRight>
                  <FirstBlockLogo></FirstBlockLogo>
                  <FirstBlockTopRightText>
                    <FirstBlockTitle>
                      {environment !== null &&
                        location !== null &&
                        environment.products[location].name}
                    </FirstBlockTitle>
                    <FirstBlockText>
                      {environment !== null &&
                      location !== null &&
                      environment.products[location].high_description
                        ? environment.products[location].high_description
                        : "Sem descrição"}
                    </FirstBlockText>
                  </FirstBlockTopRightText>
                </FirstBlockTopRight>
              </FirstBlockTop>
              <FirstBlockThumbs ref={scrollContainer.ref}>
                {environment !== null &&
                  location !== null &&
                  environment.products[location].gallery !== null &&
                  environment.products[location].gallery !== undefined &&
                  environment.products[location].gallery.map(
                    (item, index) =>
                      item.type === "image" && (
                        <FirstBlockThumb
                          key={index}
                          src={encodeURI(item.url)}
                          onClick={() => changeImage(index)}
                        />
                      )
                  )}
              </FirstBlockThumbs>
            </FirstBlock>
            <SecondBlock
              dangerouslySetInnerHTML={{
                __html: sanitizer(
                  environment !== null &&
                    location !== null &&
                    environment.products[location].description
                    ? environment.products[location].description
                    : "Sem descrição",
                  {
                    ADD_TAGS: ["iframe"],
                    ADD_ATTR: [
                      "allow",
                      "allowfullscreen",
                      "frameborder",
                      "scrolling",
                      "width",
                      "height",
                      "src",
                    ],
                  }
                ),
              }}
            />
            <ThirdBlock>
              <ThirdBlockLeftTitle>
                Você pode substituir por estes produtos
              </ThirdBlockLeftTitle>
              <ThirdBlockLeftThumbs ref={scrollContainer2.ref}>
                {environment !== null &&
                  location !== null &&
                  environment.products[location].indications !== null &&
                  environment.products[location].indications !== undefined &&
                  environment.products[location].indications.map(
                    (item, index) => (
                      <ThirdBlockLeftThumbContainer key={index}>
                        <ThirdBlockLeftThumb src={encodeURI(item.image)} />
                        <ThirdBlockLeftThumbTitle>
                          {item.name}
                        </ThirdBlockLeftThumbTitle>
                        <ThirdBlockLeftThumbSpecialties>
                          {environment !== null &&
                            location !== null &&
                            item.specialties?.map((itemSpecialty, index2) => (
                              <ThirdBlockLeftThumbSpecialty
                                key={index2}
                                src={itemSpecialty.image}
                              />
                            ))}
                        </ThirdBlockLeftThumbSpecialties>
                      </ThirdBlockLeftThumbContainer>
                    )
                  )}
              </ThirdBlockLeftThumbs>
            </ThirdBlock>
          </LeftBlock>
          <RightBlock>
            <FirstBlockRight>
              <FirstBlockRightTitle>Sistema Completo</FirstBlockRightTitle>
              <FirstBlockRightThumbs ref={scrollContainer3.ref}>
                {environment !== null &&
                  location !== null &&
                  environment.products[location].systems !== null &&
                  environment.products[location].systems !== undefined &&
                  environment.products[location].systems.map((item, index) => (
                    <FirstBlockRightThumbContainer key={index}>
                      <FirstBlockRightThumb src={encodeURI(item.image)} />
                      <FirstBlockRightThumbTitle>
                        {item.name}
                      </FirstBlockRightThumbTitle>
                    </FirstBlockRightThumbContainer>
                  ))}
              </FirstBlockRightThumbs>
            </FirstBlockRight>
          </RightBlock>
        </AllBlocks>
      </Offcanvas.Body>
    </Offcanvas>
  );
};

export default SectionContent;
