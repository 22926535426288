import React, { useState, useEffect } from "react";
import RoutesSaintGobain from "./Routes";
import PreloadProvider from "./contexts/preload";
import UserProvider from "./contexts/user";
import Preloader from "./shared/Components/Preloader";
import { GlobalStyles } from "./theme/GlobalStyles";
import Theme from "./theme";
import "bootstrap/dist/css/bootstrap.min.css";

function App() {
  return (
    <>
      <Theme>
        <GlobalStyles />
        <UserProvider>
          <PreloadProvider>
            <Preloader />
            <RoutesSaintGobain />
          </PreloadProvider>
        </UserProvider>
      </Theme>
    </>
  );
}

export default App;
