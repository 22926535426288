import React, { useEffect, useContext, useState, createContext } from "react";

const PreloadContext = createContext();

const PreloadProvider = ({ children }) => {
  const [preload, setPreload] = useState(false);

  useEffect(() => {}, [preload]);

  return (
    <PreloadContext.Provider value={{ preload, setPreload }}>
      {children}
    </PreloadContext.Provider>
  );
};

export const usePreload = () => {
  const context = useContext(PreloadContext);
  if (!context)
    throw new Error("usePreload must be used within a PreloadProvider");

  const { preload, setPreload } = context;

  return { preload, setPreload };
};

export default PreloadProvider;
