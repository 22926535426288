import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import "./index.scss";
import "normalize.css";
import "bootstrap/dist/css/bootstrap.min.css";

const rootElement = document.getElementById("root");
const root = createRoot(rootElement);

const Index = () => {
  return <App />;
};

root.render(
   <StrictMode>
    <Index />
   </StrictMode> 
);
