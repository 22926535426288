import styled from "styled-components";
import arco from "../../assets/images/Ellipse.png";
import faixaAmarela from "../../assets/images/faixa-amarela.png";

export const EspecialidadesMenu = styled.div`
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  z-index: 10;
  right: 120px;
  top: 45px;
  opacity: 0;
  transition: all 1s ease 1s;

  &.on {
    opacity: 1;
  }

  .dropdown {
    &:focus {
      outline: none !important;
      box-shadow: none !important;
    }
    button {
      font-size: 12px;
      background-color: #283891 !important;
      border-top-right-radius: 20px;
      border-bottom-right-radius: 20px;
      padding-right: 15px;

      &::after {
        margin-left: 5px;
        vertical-align: 0.16em;
      }

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .dropdown-item {
      font-size: 12px;
      line-height: 16px;
      color: #224f6f;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
  }

  &.mobile {
    flex-flow: column wrap;
    right: 90px;
    top: 32px;
    width: 80px;

    .dropdown {
      height: 20px;
      button {
        line-height: 0px;
        font-size: 10px;
        background-color: #283891 !important;
        border-top-right-radius: 0px;
        border-bottom-right-radius: 5px;
        border-bottom-left-radius: 5px;
        padding-right: 5px;

        &::after {
          margin-left: 5px;
          vertical-align: 0.16em;
        }
      }
      .dropdown-item {
        font-size: 10px;
        line-height: 16px;
        color: #224f6f;
      }
    }
  }
`;

export const EspecialidadesMenuTitle = styled.div`
  padding: 0px 10px;
  ${({ theme }) => theme.fonts.bodytitle};
  color: #224f6f;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
  background-color: #fff200;
  height: 28px;
  font-size: 12px !important;
  line-height: 28px;
  margin-top: 2px;

  &.mobile {
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    border-bottom-left-radius: 0px;
    padding: 0px 5px;
    font-size: 10px !important;
    line-height: 18px;
    height: 18px;
    margin-top: 1px;
  }
`;

export const ContainerBgHome = styled.div`
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100%;
  background-image: url(${arco});
  background-repeat: no-repeat;
  background-size: contain;
  background-position: 50% 35px;
  opacity: 1;
  overflow: hidden;
  transform: scale(1.2) translateY(10vh);
  transition: all 0.5s ease;

  @media only screen and (max-width: 600px) {
    transform: scale(1) translateY(0vh);
  }

  &.on {
    transform: scale(1) translateY(0vh);
    opacity: 1;
  }

  &.hide {
    opacity: 0;
  }
`;

export const TituloHome = styled.div`
  display: flex;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  user-select: none;
  pointer-events: none;
  ${({ theme }) => theme.fonts.displaysmall};
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  width: 194px;
  height: 394px;
  top: -80px;
  left: 50%;
  opacity: 1;
  text-align: center;
  background-image: url(${faixaAmarela});
  background-repeat: none;
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  transform: translateX(-50%);
  box-sizing: border-box;
  transition: all 1.5s ease 1s;

  @media only screen and (max-width: 600px) {
    width: 120px;
    height: 186px;
    top: -20px;
    font-size: 24px;
    transform: translateX(-50%);
  }

  @media only screen and (min-width: 601px) and (min-height: 300px) {
    width: 140px;
    height: 284px;
    transform: translateX(-50%);
    top: -70px;
  }

  @media only screen and (min-width: 601px) and (min-height: 500px) {
    width: 140px;
    height: 284px;
    transform: translateX(-50%);
    top: -70px;
  }

  @media only screen and (min-width: 601px) and (min-height: 700px) {
    width: 140px;
    height: 284px;
    transform: translateX(-50%);
    top: -70px;
  }

  @media only screen and (min-width: 601px) and (min-height: 900px) {
    width: 194px;
    height: 394px;
    transform: translateX(-50%);
    top: -80px;
  }

  &.mobile {
    width: 100px;
    height: 155px;
    top: -20px;
    font-size: 24px;
    transform: translateX(-50%);
  }

  &.on {
    left: 3%;
    opacity: 1;
    transform: translateY(0vh) translateX(0%);
  }

  &.hide {
    opacity: 0;
  }

  img {
    pointer-events: none;
    margin-top: 30px;
    width: 130px;
    height: 181px;
    transition: all 1.5s ease 1s;

    @media only screen and (max-width: 600px) {
      width: 80px;
      height: 111px;
      margin-top: 0px;
    }

    @media only screen and (min-width: 601px) and (min-height: 300px) {
      width: 80px;
      height: 111px;
      margin-top: 30px;
    }

    @media only screen and (min-width: 601px) and (min-height: 500px) {
      width: 80px;
      height: 111px;
      margin-top: 30px;
    }

    @media only screen and (min-width: 601px) and (min-height: 700px) {
      width: 80px;
      height: 111px;
      margin-top: 30px;
    }

    @media only screen and (min-width: 601px) and (min-height: 900px) {
      width: 130px;
      height: 181px;
      margin-top: 30px;
    }

    &.mobile {
      width: 60%;
      object-fit: contain;
      margin-top: 0px;
    }
  }
`;

export const AssinaturaHome = styled.div`
  counter-reset: section;
  cursor: pointer;
  z-index: 0;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-evenly;
  user-select: none;
  // pointer-events: none;
  ${({ theme }) => theme.fonts.displaysmall};
  color: ${({ theme }) => theme.colors.black};
  position: absolute;
  max-width: 200px;
  width: fit-content;
  height: 60px;
  bottom: 30px;
  right: 0px;
  opacity: 0;
  text-align: center;
  border-radius: 10px;
  margin-right: 30px;
  padding: 0px 15px;
  transform: skew(-11deg, 0deg);
  background-color: #ffffff;
  overflow: hidden;

  transition: all 0.5s ease-in-out;
  box-sizing: border-box;

  &.mobile {
    flex-flow: row wrap;
    max-width: 200px;
    width: fit-content;
    padding: 0px 5px;
    bottom: 20px;
    right: 0px;
    font-size: 24px;
  }

  &.on {
    opacity: 1;
  }

  &.open {
    max-width: 100%;
    &.mobile {
      max-width: 85%;
      height: auto;
    }
  }

  &.hide {
    opacity: 0;
  }

  .imgHolder {
    position: relative;
    visibility: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    width: 0px;
    height: 60px;
    opacity: 0;
    // background-color: white;
    border-radius: 10px;
    box-sizing: border-box;
    // animation: test 3s;
    // animation-delay: 3s;
    // animation-fill-mode: forwards;
    transition: opacity 0s ease-in-out 0s, width 0s, margin 0s,
      visibility 0s ease-in-out 2s;

    &.open {
      width: 100%;
      visibility: visible;
      padding: 0px 10px;
      opacity: 1;
      transition: opacity 0s ease-in-out 0s, width 0s ease-in-out,
        margin 0s ease-in-out, visibility 0s ease-in-out 0s;

      // &:last-child {
      //   margin-right: 0px;
      // }

      &.mobile {
        max-width: 200px;
        width: fit-content;
        padding: 0px 5px;
        margin-bottom: 0px;
        margin-top: 0px;
      }
    }

    img {
      transform: skew(11deg, 0deg);
      width: auto;

      &.saintgobain {
        height: 70%;

        &.mobile {
          height: 65%;
        }
      }
      &.quartzolit {
        height: 78%;
        &.mobile {
          height: 72%;
        }
      }
      &.brasilit {
        height: 70%;
        &.mobile {
          height: 65%;
        }
      }
      &.placo {
        height: 70%;
        &.mobile {
          height: 65%;
        }
      }
      &.isover {
        height: 65%;
        &.mobile {
          height: 60%;
        }
      }
      &.ecophon {
        height: 50%;
        &.mobile {
          height: 45%;
        }
      }
    }
  }
`;

export const AreaConhecimento = styled.div`
  pointer-events: none;
  user-select: none;
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  flex-flow: column wrap;
  justify-content: center;
  align-items: center;
  transform: translateY(5vh);
  opacity: 1;
  overflow: hidden;
  visibility: visible;
  transition: opacity 1s ease, visibility 0s;

  &.mobile {
    width: 100%;
    height: 100%;
    flex-flow: column wrap;
    transform: translateY(0vh);
    top: 30px;
  }

  &.off {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 1s;
  }
`;

export const Assinatura = styled.div`
  ${({ theme }) => theme.fonts.assinatura} !important;
  color: ${({ theme }) => theme.colors.blue};
  font-size: 40px;
  text-transform: none;
  text-align: center;
  margin-bottom: 40px;
  padding: 0rem 3rem;

  &.mobile {
    color: ${({ theme }) => theme.colors.offwhite};
  }
`;

export const AreaConhecimentoTitle = styled.div`
  display: flex;
  pointer-events: none;
  ${({ theme }) => theme.fonts.h4};
  color: ${({ theme }) => theme.colors.darkblue};
  margin-bottom: 50px;
  font-size: 22px;
  text-align: center;

  &.mobile {
    font-size: 18px;
    text-align: center;
    // white-space: pre-wrap;
    margin-bottom: 10px;
  }
`;

export const AreaConhecimentoList = styled.div`
  pointer-events: none;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 34px;

  &.mobile {
    flex-flow: row wrap;
    height: auto;
  }
`;

export const AreaConhecimentoItem = styled.div`
  height: 34px;
  pointer-events: all;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ theme }) => theme.fonts.body};
  color: ${({ theme }) => theme.colors.black};
  border-radius: 25px;
  padding: 15px 20px;
  margin: 15px;
  background-color: ${({ theme }) => theme.colors.offwhite32};

  &.mobile {
    height: 34px;
    margin: 5px;
    font-size: 10px;
    padding: 7px 15px;
    flex-flow: column nowrap;
  }
`;
