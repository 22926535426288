import { useEffect, forwardRef } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";
import { OrbitControls } from "@react-three/drei";
import { isMobile, isBrowser } from "react-device-detect";

const CameraControls = forwardRef((props, refOrbit) => {
  const {
    camera,
    gl: { domElement },
    performance,
  } = useThree();

  useEffect(() => {
    // performance.debounce = 20;
    if (isMobile) {
      camera.position.x = props.data.camPosX;
      camera.position.y = props.data.camPosY;
      camera.position.z = props.data.camPosZ;
      refOrbit.current.target = new THREE.Vector3(
        props.data.targetPosX,
        props.data.targetPosY,
        props.data.targetPosZ
      );
      refOrbit.current.update();
      // props.setIsAnimating(false);
      refOrbit.current.addEventListener("start", () => {
        // console.log(refOrbit.current.object.position, refOrbit.current.target);
        if (props.isAnimating) {
          props.setIsAnimating(false);
        }
      });
    } else {
      camera.position.x = props.data.camPosX;
      camera.position.y = props.data.camPosY;
      camera.position.z = props.data.camPosZ;
      refOrbit.current.target = new THREE.Vector3(
        props.data.targetPosX,
        props.data.targetPosY,
        props.data.targetPosZ
      );
      refOrbit.current.update();
      refOrbit.current.addEventListener("start", () => {
        // console.log(refOrbit.current.object.position, refOrbit.current.target);
        if (props.isAnimating) {
          props.setIsAnimating(false);
        }
      });
    }
  }, []);

  return (
    <OrbitControls
      ref={refOrbit}
      args={[camera, domElement]}
      minPolarAngle={props.data.minPolarAngle}
      maxPolarAngle={props.data.maxPolarAngle}
      minDistance={props.data.minDistance}
      maxDistance={props.data.maxDistance}
      enableZoom={props.data.enableZoom}
      enablePan={false}
      enableRotate={props.data.enableRotate}
      rotateSpeed={props.data.rotateSpeed}
      autoRotate={props.data.autoRotate}
      enableDamping={props.data.enableDamping}
      dampingFactor={props.data.dampingFactor}
      // regress
    />
  );
});

export default CameraControls;
