import React, { Suspense, useState, useRef, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Dropdown,
  DropdownButton,
  SplitButton,
  ButtonGroup,
} from "react-bootstrap";
import * as THREE from "three";
import { useLoader, Canvas } from "@react-three/fiber";
import {
  Stats,
  AdaptiveDpr,
  AdaptiveEvents,
  PerspectiveCamera,
  Environment,
} from "@react-three/drei";
import { isMobile, isBrowser } from "react-device-detect";
import {
  getPersonas,
  getLocal,
  getLocalProducts,
  getCategories,
  getSolutionsProducts,
} from "../../services/api";
import shadowRectTexture from "../../assets/images/shadow-rect.png";
import DirectionalLight from "./components/DirectionalLight";
import BillboardMenu from "./components/BillboardMenu";
import CameraControls from "./components/CameraControls";
import AnimateCamera from "./components/AnimateCamera";
import HomeMenu from "./components/HomeMenu";
import LogosFooter from "./components/LogosFooter";
import Nuvens from "./components/Nuvens";
import PreloaderCanvas from "../../shared/Components/PreloaderCanvas";
import { configData, configDataMobile, locationsOut } from "./setup";
import "./style.scss";
import {
  EspecialidadesMenu,
  EspecialidadesMenuTitle,
  ContainerBgHome,
  TituloHome,
  AreaConhecimento,
  Assinatura,
  AreaConhecimentoTitle,
  AreaConhecimentoList,
  AreaConhecimentoItem,
  AssinaturaHome,
} from "./styled";
import { forEach } from "lodash";
import logoCidadeInteligente from "../../assets/images/logo-cidade-inteligente-new.png";
import logoSaintGobain from "../../assets/images/logos/saintgobain.png";
import logoBrasilit from "../../assets/images/logos/brasilit.png";
import logoEcophon from "../../assets/images/logos/ecophon.png";
import logoIsover from "../../assets/images/logos/isover.png";
import logoPlaco from "../../assets/images/logos/placo.png";
import logoQuartzolit from "../../assets/images/logos/quartzolit.png";
import Preloader from "../../shared/Components/Preloader";
import { KhanacademyDimensions } from "@styled-icons/simple-icons/Khanacademy";
import Cidade from "../../assets/Modelos/cidade";
import leLombo from "../../assets/images/lebombo_1k.hdr";

// const Cidade = React.lazy(() => import("../../assets/Modelos/cidade"));

const Home = ({
  burgerMenuOn,
  setBurgerMenuOn,
  secondaryMenuOn,
  setSecondaryMenuOn,
  isAnimating,
  setIsAnimating,
  firstView,
  setFirstView,
  apiToken,
}) => {
  let { firstview } = useParams();
  const refOrbit = useRef(null);
  const [placeHighlighted, setPlaceHighlighted] = useState(null);
  const [location, setLocation] = useState(null);
  const [locationProps, setLocationProps] = useState(locationsOut);
  const [assinaturaHome, setAssinaturaHome] = useState({
    open: false,
    logoDaVez: 0,
    logos: [
      { src: logoSaintGobain, name: "saintgobain" },
      { src: logoQuartzolit, name: "quartzolit" },
      { src: logoBrasilit, name: "brasilit" },
      { src: logoPlaco, name: "placo" },
      { src: logoIsover, name: "isover" },
      { src: logoEcophon, name: "ecophon" },
    ],
  });
  const [tabMenu, setTabMenu] = useState([
    {
      name: "Locais",
      selected: true,
      items: locationProps,
    },
    {
      name: "Soluções",
      selected: false,
      items: null,
    },
  ]);
  const [persona, setPersona] = useState(null);

  const [data, setData] = useState(isMobile ? configDataMobile : configData);
  const colorMap = useLoader(THREE.TextureLoader, shadowRectTexture);
  const clone = require("rfdc")();

  const doInterval = () => {};

  useEffect(() => {
    if (firstView) {
      setData((values) => ({
        ...values,
        animationSpeed: 1,
        camPosX: isMobile ? 400 : 400,
        camPosY: isMobile ? 150 : 150,
        camPosZ: isMobile ? -550 : -120,
        targetPosX: isMobile ? 0 : -210,
        targetPosY: isMobile ? 200 : 100,
        targetPosZ: isMobile ? 0 : 50,
        maxPolarAngle: Math.PI,
        far: 50,
      }));
    } else {
      document.body.style.pointerEvents = "none";
      setTimeout(() => {
        document.body.style.pointerEvents = "all";
      }, 3000);
      setIsAnimating(true);
      setData((values) => ({
        ...values,
        animationSpeed: 0.025,
        camPosX: isMobile ? configDataMobile.camPosX : configData.camPosX,
        camPosY: isMobile ? configDataMobile.camPosY : configData.camPosY,
        camPosZ: isMobile ? configDataMobile.camPosZ : configData.camPosZ,
        targetPosX: isMobile
          ? configDataMobile.targetPosX
          : configData.targetPosX,
        targetPosY: isMobile
          ? configDataMobile.targetPosY
          : configData.targetPosY,
        targetPosZ: isMobile
          ? configDataMobile.targetPosZ
          : configData.targetPosZ,
        maxPolarAngle: Math.PI / 2.5,
        minPolarAngle: Math.PI / 2.8,
        far: isMobile ? configDataMobile.far : configData.far,
      }));
    }
  }, [firstView]);

  useEffect(() => {
    // console.log(locationProps);
  }, [locationProps]);

  useEffect(() => {
    // setTabMenu((values) => ({
    //   ...values,
    // }));
  }, [tabMenu]);

  useEffect(() => {
    // console.log("start timer");
    const timerLogos = setInterval(() => {
      // console.log("timer in");
      setAssinaturaHome((values) => ({
        ...values,
        logoDaVez:
          values.logoDaVez < values.logos.length - 1 ? values.logoDaVez + 1 : 0,
      }));
    }, 10000);

    getLocalProducts()
      .then((res) => {
        let locationPropsTemp = clone(locationProps);
        locationPropsTemp.forEach((item) => {
          res.forEach((itemApi) => {
            if (item.id === itemApi.id) {
              item.name = itemApi.name;
              item.description = itemApi.description;
              item.products = itemApi.products;
            }
          });
        });
        setLocationProps(locationPropsTemp);
        let tabMenuTemp = clone(tabMenu);
        tabMenuTemp[0].items = locationPropsTemp;
        tabMenuTemp[0].items.forEach((item) => {
          item.selected = false;
        });
        tabMenuTemp[0].items.sort((a, b) => (a.name > b.name ? 1 : -1));
        // console.log(tabMenuTemp);
        getSolutionsProducts()
          .then((res) => {
            tabMenuTemp[1].items = res;
            tabMenuTemp[1].items.forEach((item) => {
              item.selected = false;
              item.micro_solutions.map((category, o) => {
                category.environments = Object.entries(
                  category.environments
                ).map(
                  (value) =>
                    value[1][0].environment && value[1][0].environment.trim()
                );
              });
            });
            tabMenuTemp[1].items.sort((a, b) => (a.name > b.name ? 1 : -1));
            console.log(tabMenuTemp);
            setTabMenu(tabMenuTemp);

            setTimeout(() => {
              if (firstview === "false") {
                setFirstView(false);

                getPersonas()
                  .then((res) => {
                    let personasTemp = res.data.data;
                    let local = localStorage.getItem("areaDeConhecimento");
                    if (local) {
                      let newPersonasTemp = personasTemp.map((obj) => {
                        return obj.name === local
                          ? { ...obj, selected: true }
                          : { ...obj, selected: false };
                      });
                      setPersona(newPersonasTemp);
                    }
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              } else {
                getPersonas()
                  .then((res) => {
                    setPersona(res.data.data);
                  })
                  .catch((err) => {
                    console.log(err);
                  });
              }
            }, 100);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });

    // getCategories(apiToken)
    //   .then((res) => {
    //     console.log(res);
    //   })
    //   .catch((err) => {
    //     console.log(err);
    //   });

    return () => clearInterval(timerLogos);
  }, []);

  useEffect(() => {
    if (persona !== null) {
    }
  }, [persona]);

  const chooseArea = (area) => {
    setPersona((values) =>
      values.map((obj) => {
        if (obj.name === area) {
          localStorage.setItem("areaDeConhecimento", obj.name);
        }
        return obj.name === area
          ? { ...obj, selected: true }
          : { ...obj, selected: false };
      })
    );
    setFirstView(false);
  };

  const goOut = () => {
    if (location !== null) {
      let locationProp = locationProps.find((prop) => prop.id === location);

      setData((values) => ({
        ...values,
        camPosX: locationProp.cameraOut.pos.x,
        camPosY: locationProp.cameraOut.pos.y,
        camPosZ: locationProp.cameraOut.pos.z,
        targetPosX: locationProp.cameraOut.target[0],
        targetPosY: locationProp.cameraOut.target[1],
        targetPosZ: locationProp.cameraOut.target[2],
      }));
      setIsAnimating(true);
      setPlaceHighlighted(null);
      setLocation(null);
      refOrbit.current.enableRotate = true;
      refOrbit.current.enableZoom = true;
    }
  };

  const openLogos = () => {
    setAssinaturaHome((values) => ({
      ...values,
      open: true,
    }));
  };
  const closeLogos = () => {
    setAssinaturaHome((values) => ({
      ...values,
      open: false,
    }));
  };

  return (
    <>
      {/* <Stats /> */}
      <EspecialidadesMenu
        className={`${!firstView ? "on" : ""} ${isMobile && "mobile"}`}
      >
        {persona && persona.find((obj) => obj.selected) !== undefined && (
          <>
            <EspecialidadesMenuTitle className={isMobile && "mobile"}>
              Especialidade:
            </EspecialidadesMenuTitle>
            {[DropdownButton].map((DropdownType, idx) => (
              <DropdownType
                as={ButtonGroup}
                key={idx}
                id={`dropdown-button-drop-${idx}`}
                size="sm"
                title={
                  persona !== null &&
                  persona.find((element) => element.selected)?.name
                }
                className="dropdown"
              >
                {persona !== null &&
                  persona.map((element, index) => (
                    <Dropdown.Item
                      key={element.name}
                      eventKey={index}
                      onClick={() => chooseArea(element.name)}
                    >
                      {element.name}
                    </Dropdown.Item>
                  ))}
              </DropdownType>
            ))}
          </>
        )}
      </EspecialidadesMenu>

      <Canvas
        mode={"concurrent"}
        // invalidateFrameloop={shouldInvalidate}
        performance={{ min: 0.7 }}
        className={"canvas"}
        // shadows
        dpr={[0.5, 1]}
        frameloop={"demand"}
        flat={true}
        linear={false}
        gl={{
          // alpha: true,
          outputEncoding: THREE.sRGBEncoding,
          antialias: true,
          logarithmicDepthBuffer: true,
          powerPreference: "high-performance",
        }}
        style={{
          position: "absolute",
          zIndex: 0,
          pointerEvents: firstView && "none",
          transform: burgerMenuOn
            ? "translateX(-470px) translateY(20vh)"
            : "translateX(0vw) translateY(0vh)",
          transition: "transform 0.5s ease-in-out",
        }}
        onPointerDown={(e) => {
          e.stopPropagation();
          // console.log(e);

          if (location !== null) {
            goOut();
          }
        }}
      >
        <PerspectiveCamera
          makeDefault
          // position={[data.camPosX, data.camPosY, data.camPosZ]}
          fov={data.fov}
          near={data.near}
          far={data.far}
        />
        {/* {data.fog && (
          <fog
            attach="fog"
            color={data.fogColor}
            near={data.fogNear}
            far={data.fogFar}
          />
        )} */}
        <color attach="background" args={[data.backgroundColor]} />
        {isBrowser && (
          <>
            <ambientLight intensity={2.5} />
            {/* <DirectionalLight
              castShadow={false}
              pos={[360, 100, 216]}
              targetPos={[0, 0, 0]}
              intensity={5}
              lightColor={"#ffaa66"}
              setIsAnimating={setIsAnimating}
            />
            <DirectionalLight
              castShadow={false}
              pos={[0.4, -0.5, 0.8]}
              targetPos={[0, 0, 0]}
              intensity={2}
              lightColor={"#ff6622"}
              setIsAnimating={setIsAnimating}
            /> */}
            <Suspense fallback={<PreloaderCanvas />}>
              <Cidade
                scale={1}
                placeHighlighted={placeHighlighted}
                setPlaceHighlighted={setPlaceHighlighted}
                setIsAnimating={setIsAnimating}
                setData={setData}
                location={location}
                setLocation={setLocation}
                locationProps={locationProps}
                refOrbit={refOrbit}
              />
              {!firstView && persona && (
                <BillboardMenu
                  follow={true}
                  lockX={false}
                  lockY={false}
                  lockZ={false}
                  setPlaceHighlighted={setPlaceHighlighted}
                  location={location}
                  setLocation={setLocation}
                  tabMenu={tabMenu}
                  setTabMenu={setTabMenu}
                  setIsAnimating={setIsAnimating}
                  setData={setData}
                  locationProps={locationProps}
                  refOrbit={refOrbit}
                  persona={persona}
                />
              )}
              <CameraControls
                ref={refOrbit}
                data={data}
                isAnimating={isAnimating}
                setIsAnimating={setIsAnimating}
              />
              <AnimateCamera
                data={data}
                isAnimating={isAnimating}
                setIsAnimating={setIsAnimating}
                refOrbit={refOrbit}
              />
              <Environment files={leLombo} />
            </Suspense>
          </>
        )}
        {isMobile && (
          <>
            <ambientLight intensity={2.5} />
            {/* <DirectionalLight
              castShadow={false}
              pos={[360, 100, 216]}
              targetPos={[0, 0, 0]}
              intensity={5}
              lightColor={"#ffaa66"}
              setIsAnimating={setIsAnimating}
            />
            <DirectionalLight
              castShadow={false}
              pos={[0.4, -0.5, 0.8]}
              targetPos={[0, 0, 0]}
              intensity={2}
              lightColor={"#ff6622"}
              setIsAnimating={setIsAnimating}
            /> */}
            <Suspense fallback={<PreloaderCanvas />}>
              <Cidade
                scale={1}
                placeHighlighted={placeHighlighted}
                setPlaceHighlighted={setPlaceHighlighted}
                setIsAnimating={setIsAnimating}
                setData={setData}
                location={location}
                setLocation={setLocation}
                locationProps={locationProps}
                refOrbit={refOrbit}
              />
              {!firstView && !secondaryMenuOn && persona && (
                <BillboardMenu
                  follow={true}
                  lockX={false}
                  lockY={false}
                  lockZ={false}
                  setPlaceHighlighted={setPlaceHighlighted}
                  location={location}
                  setLocation={setLocation}
                  tabMenu={tabMenu}
                  setTabMenu={setTabMenu}
                  setIsAnimating={setIsAnimating}
                  setData={setData}
                  locationProps={locationProps}
                  refOrbit={refOrbit}
                  persona={persona}
                />
              )}
              <CameraControls
                ref={refOrbit}
                minPolarAngle={Math.PI / 4.5}
                maxPolarAngle={Math.PI / 3}
                data={data}
                isAnimating={isAnimating}
                setIsAnimating={setIsAnimating}
              />
              <AnimateCamera
                data={data}
                isAnimating={isAnimating}
                setIsAnimating={setIsAnimating}
                refOrbit={refOrbit}
                secondaryMenuOn={secondaryMenuOn}
                setSecondaryMenuOn={setSecondaryMenuOn}
              />
              <Environment files={leLombo} />
            </Suspense>
          </>
        )}
        {/* <AdaptivePixelRatio /> */}
        {/* <AdaptiveDpr />
        <AdaptiveEvents /> */}
      </Canvas>
      <HomeMenu
        firstView={firstView}
        setPlaceHighlighted={setPlaceHighlighted}
        location={location}
        setLocation={setLocation}
        setData={setData}
        locationProps={locationProps}
        refOrbit={refOrbit}
        className={`${isBrowser && !firstView && !burgerMenuOn && "on"} ${
          isMobile && !firstView && "mobile"
        } ${secondaryMenuOn && "on"}`}
        tabMenu={tabMenu}
        setTabMenu={setTabMenu}
        secondaryMenuOn={secondaryMenuOn}
        setSecondaryMenuOn={setSecondaryMenuOn}
        isAnimating={isAnimating}
        setIsAnimating={setIsAnimating}
        persona={persona}
      />
      {/* <LogosFooter /> */}
      {/* <ContainerBgHome
        className={`${!firstView && "on"} ${burgerMenuOn && "hide"}`}
      /> */}
      {isBrowser && <Nuvens firstView={firstView} />}
      {isMobile && <Nuvens firstView={false} />}
      <TituloHome
        className={`${!firstView ? "on" : ""} ${burgerMenuOn ? "hide" : ""} ${
          isMobile && "mobile"
        }`}
      >
        <img
          src={logoCidadeInteligente}
          width="100%"
          className={`${isMobile && "mobile"}`}
        />
      </TituloHome>
      <AssinaturaHome
        className={`${!firstView ? "on" : ""} ${burgerMenuOn ? "hide" : ""} ${
          isMobile && "mobile"
        } ${assinaturaHome.open && "open"}`}
        onPointerOver={() => openLogos()}
        onPointerOut={() => closeLogos()}
      >
        {assinaturaHome.logos.map((logo, index) => {
          return (
            <div
              key={logo.name}
              className={`imgHolder ${isMobile && "mobile"} ${
                assinaturaHome.open
                  ? "on open"
                  : assinaturaHome.logoDaVez === index
                  ? "on open"
                  : ""
              }`}
            >
              <img
                src={logo.src}
                className={`${isMobile && "mobile"}  ${logo.name}`}
              />
            </div>
          );
        })}
      </AssinaturaHome>
      <AreaConhecimento
        className={`${!firstView && "off"} ${isMobile && "mobile"}`}
      >
        <Assinatura className={`${isMobile && "mobile"}`}>
          Construindo o seu mundo com inovação e sustentabilidade
        </Assinatura>
        {firstview !== "false" && (
          <AreaConhecimentoTitle className={isMobile && "mobile"}>
            {isBrowser
              ? "Para iniciar, selecione sua especialidade"
              : `Selecione sua área
de conhecimento`}
          </AreaConhecimentoTitle>
        )}
        <AreaConhecimentoList className={isMobile && "mobile"}>
          {firstview !== "false" &&
            persona !== null &&
            persona.map((item, index) => (
              <AreaConhecimentoItem
                key={index}
                onClick={() => chooseArea(item.name)}
                className={isMobile && "mobile"}
              >
                {item.name}
              </AreaConhecimentoItem>
            ))}
        </AreaConhecimentoList>
      </AreaConhecimento>
    </>
  );
};

export default Home;
