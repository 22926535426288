import styled, { keyframes } from "styled-components";

const nuvem1 = keyframes`
  0% {
    top:-20vh;
    left: -20vw;
  }

  50% {
    top: 10vh;
    left: 10vw;
  }

  100% {
    top:-20vh;
    left: -20vw;
  }
`;

const nuvem2 = keyframes`
  0% {
    top:20vh;
    right: -20vw;
  }

  50% {
    top: 20vh;
    right: 5vw;
  }

  100% {
    top:20vh;
    right: -20vw;
  }
`;

const nuvem3 = keyframes`
  0% {
    bottom: -10vh;
    left: -20vw;
  }

  50% {
    bottom: -5vh;
    left: -5vw;
  }

  100% {
    bottom: -10vh;
    left: -20vw;
  }
`;

const nuvem4 = keyframes`
  0% {
    bottom: -20vh;
    right: -10vw;
  }

  50% {
    bottom: -10vh;
    right: -5vw;
  }

  100% {
    bottom: -20vh;
    right: -0vw;
  }
`;

export const ContainerNuvens = styled.div`
  pointer-events: none;
  position: absolute;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
`;

export const Nuvem = styled.img`
  pointer-events: none;

  position: absolute;

  &.nuvem1 {
    transform: scale(1.5) translateX(0) translateY(0);
    animation: ${nuvem1} 40s linear infinite;
    transition: all 3s ease;
    &.in {
      transform: scale(0.5) translateX(-50%) translateY(-50%);
      @media only screen and (max-width: 600px) {
        transform: scale(0.5) translateX(-100%) translateY(-70%);
      }
      &.mobile {
        transform: scale(0.3) translateX(-150%) translateY(-150%);
      }
    }
  }
  &.nuvem2 {
    transform: scale(2) translateX(0) translateY(0);
    animation: ${nuvem2} 70s linear infinite;
    transition: all 3s ease;
    &.in {
      transform: scale(0.5) translateX(50%) translateY(-50%);
      @media only screen and (max-width: 600px) {
        transform: scale(0.5) translateX(120%) translateY(-50%);
      }
      &.mobile {
        transform: scale(0.3) translateX(150%) translateY(-150%);
      }
    }
  }
  &.nuvem3 {
    transform: scale(1.7) translateX(0) translateY(0);
    animation: ${nuvem3} 60s linear infinite;
    transition: all 3s ease;
    &.in {
      transform: scale(0.5) translateX(-50%) translateY(50%);
      @media only screen and (max-width: 600px) {
        transform: scale(0.5) translateX(-120%) translateY(0%);
      }
      &.mobile {
        transform: scale(0.3) translateX(-100%) translateY(70%);
      }
    }
  }
  &.nuvem4 {
    transform: scale(1.4) translateX(0) translateY(0);
    animation: ${nuvem4} 50s linear infinite;
    transition: all 3s ease;
    &.in {
      transform: scale(0.5) translateX(50%) translateY(50%);
      @media only screen and (max-width: 600px) {
        transform: scale(0.5) translateX(100%) translateY(50%);
      }
      &.mobile {
        transform: scale(0.3) translateX(150%) translateY(70%);
      }
    }
  }
`;
