import styled from "styled-components";

export const InternaLocationPin = styled.div`
  position: absolute;
  width: auto;
  min-width: 250px;
  height: 40px;
  left: -70px;
  top: 43px;
  z-index: 10;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.yellow};
  white-space: pre;
  border-radius: 4px;
  padding: 0px 30px 0px 100px;
  ${({ theme }) => theme.fonts.caption};
  font-size: 14px;
  text-transform: capitalize;
  text-rendering: geometricPrecision;
  transform: skew(-11deg, 0deg);

  &.mobile {
    top: 30px;
  }

  img {
    height: 30px;
    object-fit: contain;
    margin-right: 20px;
    transform: skew(11deg, 0deg);
  }

  span {
    transform: skew(11deg, 0deg);
  }
`;

export const EnvironmentsMenu = styled.div`
  text-transform: none !important;
  position: absolute;
  display: flex;
  flex-flow: row nowrap;
  z-index: 10;
  top: 0px;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  transition: all 1s ease 1s;
  transform: skew(11deg, 0deg);
  color: #000000;

  &.on {
    opacity: 1;
  }

  .dropdown {
    width: 100%;
    height: 100%;

    &:focus {
      outline: none !important;
      box-shadow: none !important;
      color: #000000;
    }
    button {
      width: 100%;
      opacity: 1;
      color: #000000;
      background-color: transparent !important;
      text-align: right;
      border: none;
      ${({ theme }) => theme.fonts.caption};

      &::after {
        margin-right: 5px;
        vertical-align: 0.16em;
        color: #000000;
        font-size: 1rem;
      }

      &:focus {
        outline: none !important;
        box-shadow: none !important;
      }
    }
    .dropdown-menu {
      margin-top: 0px;
      border: none;
      background-color: ${({ theme }) => theme.colors.yellow};
      font-size: 0.7rem;
      text-transform: capitalize;

      transform: translate3d(-5px, 50px, 0) !important;
    }
    .dropdown-item {
      color: #000000;

      &:focus {
        outline: none !important;
        box-shadow: none !important;
        color: ${({ theme }) => theme.colors.offwhite};
        background-color: ${({ theme }) => theme.colors.blue};
      }

      &:hover {
        outline: none !important;
        box-shadow: none !important;
        color: ${({ theme }) => theme.colors.offwhite};
        background-color: ${({ theme }) => theme.colors.blue};
      }
    }
  }

  &.mobile {
    flex-flow: column wrap;

    .dropdown {
      button {
        line-height: 0px;
        font-size: 10px;
        background-color: transparent !important;
        padding-right: 5px;

        &::after {
          margin-left: 5px;
          vertical-align: 0.16em;
        }
      }
      .dropdown-item {
        color: #000000;
        &:focus {
          outline: none !important;
          box-shadow: none !important;
          color: ${({ theme }) => theme.colors.offwhite};
          background-color: ${({ theme }) => theme.colors.blue};
        }

        &:hover {
          outline: none !important;
          box-shadow: none !important;
          color: ${({ theme }) => theme.colors.offwhite};
          background-color: ${({ theme }) => theme.colors.blue};
        }
      }
    }
  }
`;
