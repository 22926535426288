import { ArrowRight, ArrowUpAll } from "../../assets/images";
const productsHospital = [
  {
    id: 0,
    name: "Entrar",
    environmentArea: 0,
    category: { image: ArrowRight },
    pinPos: [104.90330051778398, 224.54399236796877, -89.77355142224744],
    boardPos: [-48, 78, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 342.535544163025,
        y: 187.50210884677762,
        z: -115.37303428762846,
      },
      target: {
        x: 342.5777632984701,
        y: 187.48858837967535,
        z: -114.37401740009771,
      },
      posMobile: {
        x: 342.535544163025,
        y: 187.50210884677762,
        z: -115.37303428762846,
      },
      targetMobile: {
        x: 342.5777632984701,
        y: 187.48858837967535,
        z: -114.37401740009771,
      },
    },
    cameraOut: {
      pos: {
        x: 74.25036759688784,
        y: 230.6801244500861,
        z: 174.96815084818667,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: -1,
    name: "Sair",
    environmentArea: 0,
    category: { image: ArrowUpAll },
    pinPos: [205.72315001040445, 206, -94.90747498001951],
    boardPos: [-48, 78, 0],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 66.84358145103943,
        y: 233.6811344867689,
        z: 157.0332234581075,
      },
      target: {
        x: 65.87810874024639,
        y: 233.58089828382273,
        z: 157.27367126602616,
      },
      posMobile: {
        x: 66.84358145103943,
        y: 233.6811344867689,
        z: 157.0332234581075,
      },
      targetMobile: {
        x: 65.87810874024639,
        y: 233.58089828382273,
        z: 157.27367126602616,
      },
    },
    cameraOut: {
      pos: {
        x: 74.25036759688784,
        y: 230.6801244500861,
        z: 174.96815084818667,
      },
      target: {
        x: 74,
        y: 230.6801642501449,
        z: 174,
      },
    },
  },
  {
    id: 2,
    name: "Placa Gesso Habito Placo",
    pinPos: [-395.0018704898568, 259.82673185980883, 549.9194251273558],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -315.1303640774286,
        y: 298.8557435518424,
        z: 451.18519423003687,
      },
      target: {
        x: -315.56644806981836,
        y: 298.53522557553697,
        z: 452.02608600574075,
      },
      posMobile: {
        x: -315.1303640774286,
        y: 298.8557435518424,
        z: 451.18519423003687,
      },
      targetMobile: {
        x: -315.56644806981836,
        y: 298.53522557553697,
        z: 452.02608600574075,
      },
    },
    cameraOut: {
      pos: {
        x: 66.61805541105382,
        y: 233.5506819906685,
        z: 156.60168473343117,
      },
      target: {
        x: 65.87810874024639,
        y: 233.58089828382273,
        z: 157.27367126602616,
      },
    },
  },
  {
    id: 3,
    name: "Placa Gesso Performa Placo",
    environmentArea: 0,
    pinPos: [450.47221077977014, 186.46521733495086, 205.34791551761552],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 268.06866275924943,
        y: 230.7580705871094,
        z: 89.48578777716428,
      },
      target: {
        x: 268.9736819893112,
        y: 230.5039422956961,
        z: 89.82690213441383,
      },
      posMobile: {
        x: 268.06866275924943,
        y: 230.7580705871094,
        z: 89.48578777716428,
      },
      targetMobile: {
        x: 268.9736819893112,
        y: 230.5039422956961,
        z: 89.82690213441383,
      },
    },
    cameraOut: {
      pos: {
        x: 342.16101383679285,
        y: 187.5159833793225,
        z: -115.22930239750586,
      },
      target: {
        x: 342.4856042176008,
        y: 187.50393018899587,
        z: -114.28352451380911,
      },
    },
  },
  {
    id: 4,
    name: "Placa Placa Gesso LEV Placo",
    pinPos: [-186.26965542557406, 364.8896816064833, 439.34321924211605],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -47.96545008760073,
        y: 114.0043549118614,
        z: 461.5573000935563,
      },
      target: {
        x: -48.39284628011109,
        y: 114.90400935340422,
        z: 461.64648742696147,
      },
      posMobile: {
        x: -47.96545008760073,
        y: 114.0043549118614,
        z: 461.5573000935563,
      },
      targetMobile: {
        x: -48.39284628011109,
        y: 114.90400935340422,
        z: 461.64648742696147,
      },
    },
    cameraOut: {
      pos: {
        x: 66.48597087071532,
        y: 233.23774471170535,
        z: 156.55760614588354,
      },
      target: {
        x: 65.87810874024639,
        y: 233.58089828382273,
        z: 157.27367126602616,
      },
    },
  },
  {
    id: 6,
    name: "supergraute quartzolit",
    environmentArea: 0,
    pinPos: [449.9316117531292, 197.80163040832667, 860.6191601424243],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 265.0451099193452,
        y: 260.7174783499207,
        z: 305.13851437479974,
      },
      target: {
        x: 265.5377127735918,
        y: 260.5801922641327,
        z: 305.99787166873347,
      },
      posMobile: {
        x: 265.0451099193452,
        y: 260.7174783499207,
        z: 305.13851437479974,
      },
      targetMobile: {
        x: 265.5377127735918,
        y: 260.5801922641327,
        z: 305.99787166873347,
      },
    },
    cameraOut: {
      pos: {
        x: 342.3523803216412,
        y: 187.49842579196056,
        z: -115.27459519502142,
      },
      target: {
        x: 342.4856042176008,
        y: 187.50393018899587,
        z: -114.28352451380911,
      },
    },
  },
  {
    id: 19,
    name: "Painel Wallfelt Isover",
    pinPos: [-420.7497641452783, 352.68487887427835, 558.8010685706141],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -275.8457781407918,
        y: 368.31718048204283,
        z: 499.0239904179566,
      },
      target: {
        x: -276.67174844727305,
        y: 368.2805710741662,
        z: 499.5865140210758,
      },
      posMobile: {
        x: -275.8457781407918,
        y: 368.31718048204283,
        z: 499.0239904179566,
      },
      targetMobile: {
        x: -276.67174844727305,
        y: 368.2805710741662,
        z: 499.5865140210758,
      },
    },
    cameraOut: {
      pos: {
        x: 66.60834662818782,
        y: 233.37277906965102,
        z: 156.62294934407532,
      },
      target: {
        x: 65.87810874024639,
        y: 233.58089828382273,
        z: 157.27367126602616,
      },
    },
  },
  {
    id: 24,
    name: "Sistema Nextera com Placa Cimentícia Brasilit",
    pinPos: [-476.0362275549619, 170.26964433674726, 487.77788301858584],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -283.9506585431639,
        y: 202.8989225491549,
        z: 379.37595522412374,
      },
      target: {
        x: -284.7186778942051,
        y: 202.74590812535706,
        z: 379.9978337191178,
      },
      posMobile: {
        x: -283.9506585431639,
        y: 202.8989225491549,
        z: 379.37595522412374,
      },
      targetMobile: {
        x: -284.7186778942051,
        y: 202.74590812535706,
        z: 379.9978337191178,
      },
    },
    cameraOut: {
      pos: {
        x: 66.70425051992365,
        y: 233.7157240298166,
        z: 156.72657732702766,
      },
      target: {
        x: 65.87810874024639,
        y: 233.58089828382273,
        z: 157.27367126602616,
      },
    },
  },
  {
    id: 31,
    name: "Forros Hygiene Meditec A Ecophon A",
    environmentArea: 0,
    pinPos: [236.63971627742663, 366.29176722601727, 676.8826731965693],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 418.1886254312463,
        y: 283.182424021969,
        z: 400.53965504238306,
      },
      target: {
        x: 417.800701714078,
        y: 283.42622518505806,
        z: 401.42851733910555,
      },
      posMobile: {
        x: 418.1886254312463,
        y: 283.182424021969,
        z: 400.53965504238306,
      },
      targetMobile: {
        x: 417.800701714078,
        y: 283.42622518505806,
        z: 401.42851733910555,
      },
    },
    cameraOut: {
      pos: {
        x: 342.74297480084726,
        y: 187.21498966962176,
        z: -115.20562741432683,
      },
      target: {
        x: 342.4856042176008,
        y: 187.50393018899587,
        z: -114.28352451380911,
      },
    },
  },
  {
    id: 32,
    name: "Painel Nexacustic 32 Sonex",
    pinPos: [124.77906155599078, 180.84918441003245, 350.82263615723764],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: -172.31305536764074,
        y: 191.36369136631535,
        z: 346.7969733530686,
      },
      target: {
        x: -171.32210622714064,
        y: 191.34960926533648,
        z: 346.6634761794128,
      },
      posMobile: {
        x: -172.31305536764074,
        y: 191.36369136631535,
        z: 346.7969733530686,
      },
      targetMobile: {
        x: -171.32210622714064,
        y: 191.34960926533648,
        z: 346.6634761794128,
      },
    },
    cameraOut: {
      pos: {
        x: 65.47418392346373,
        y: 233.7811158215793,
        z: 156.42471998160894,
      },
      target: {
        x: 65.78081225931932,
        y: 233.56357555160227,
        z: 157.3513565309576,
      },
    },
  },
  {
    id: 39,
    name: "impermeabilizante tecplus top quartzolit",
    environmentArea: 0,
    pinPos: [359.52382750172586, 23.792844633694305, 181.73770150145964],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 425.6132219164841,
        y: 216.19229498352345,
        z: 0.9559261804199294,
      },
      target: {
        x: 425.5457093681888,
        y: 215.4504584996373,
        z: 1.6230998362233569,
      },
      posMobile: {
        x: 425.6132219164841,
        y: 216.19229498352345,
        z: 0.9559261804199294,
      },
      targetMobile: {
        x: 425.5457093681888,
        y: 215.4504584996373,
        z: 1.6230998362233569,
      },
    },
    cameraOut: {
      pos: {
        x: 342.3776987489963,
        y: 188.02365575668853,
        z: -115.13101595573157,
      },
      target: {
        x: 342.4856042176008,
        y: 187.50393018899587,
        z: -114.28352451380911,
      },
    },
  },
  {
    id: 49,
    name: "contrapiso autonivelante RAD quartzolit",
    environmentArea: 0,
    pinPos: [270.0472753361561, 20.396989836930107, 76.05460553429562],
    animationSpeed: 0.1,
    selected: false,
    hovered: false,
    cameraIn: {
      pos: {
        x: 413.3451638461734,
        y: 217.2419919144218,
        z: 1.094722903798591,
      },
      target: {
        x: 412.88115617967554,
        y: 216.47594747130282,
        z: 1.5395518558664052,
      },
      posMobile: {
        x: 413.3451638461734,
        y: 217.2419919144218,
        z: 1.094722903798591,
      },
      targetMobile: {
        x: 412.88115617967554,
        y: 216.47594747130282,
        z: 1.5395518558664052,
      },
    },
    cameraOut: {
      pos: {
        x: 342.75003623368303,
        y: 188.15965979727326,
        z: -114.99069803544228,
      },
      target: {
        x: 342.4856042176008,
        y: 187.50393018899587,
        z: -114.28352451380911,
      },
    },
  },
];

export default productsHospital;
