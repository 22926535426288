import styled from "styled-components";

export const Container = styled.div`
  pointer-events: none;
  position: absolute;
  z-index: 1;
  display: flex;
  flex-flow: row nowrap;
  justify-content: end;
  width: 100vw;
  height: 70px;
  margin: 0;
  padding: 0;
  opacity: 1;
  overflow: visible;
  visibility: visible;
  transition: opacity 1s ease, visibility 0s;

  &.off {
    opacity: 0;
    visibility: hidden;
    transition: opacity 1s ease, visibility 2s;
  }

  
`;

export const TitleClose = styled.div`
 ${({ theme }) => theme.fonts.caption}
 color: ${({ theme }) => theme.colors.black};
 margin-top:30px;
 margin-right:20px;
 line-height: 60px;
`;
