import { useEffect } from "react";
import * as THREE from "three";
import { useThree } from "@react-three/fiber";

const DirectionalLight = (props) => {
  const targetObject = new THREE.Object3D();
  const { scene, gl } = useThree();
  scene.add(targetObject);
  targetObject.position.set(
    props.targetPos[0],
    props.targetPos[1],
    props.targetPos[2]
  );
  const light = new THREE.DirectionalLight(props.lightColor, props.intensity);
  light.position.set(props.pos[0], props.pos[1], props.pos[2]);
  light.target = targetObject;
  light.castShadow = props.castShadow;
  light.shadow.mapSize.width = 2048;
  light.shadow.mapSize.height = 2048;
  light.shadow.camera.near = 1;
  light.shadow.camera.far = 30000;
  light.shadow.camera.top = -4000;
  light.shadow.camera.right = 4000;
  light.shadow.camera.left = -4000;
  light.shadow.camera.bottom = 4000;
  light.shadow.bias = -0.001;

  useEffect(() => {
    gl.getContext().canvas.addEventListener(
      "selectstart",
      function(e) {
        e.preventDefault();
        return false;
      },
      false
    );
  }, []);

  return <primitive object={light} />;
};

export default DirectionalLight;
