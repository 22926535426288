import { useEffect, useState } from "react";
import * as THREE from "three";
import { useFrame, useThree, invalidate } from "@react-three/fiber";

const lerp = (start, end, amt) => {
  return (1 - amt) * start + amt * end;
};

const AnimateCamera = ({
  data,
  setData,
  locationProps,
  location,
  isHover,
  isAnimating,
  setIsAnimating,
  refOrbit,
  secondaryMenuOn,
  setSecondaryMenuOn,
  local,
}) => {
  const { camera, performance } = useThree();

  useEffect(() => {
    // performance.debounce = 150;
    refOrbit.current.object.position.equals = (v, epsilon = Number.EPSILON) => {
      return (
        Math.abs(v.x - refOrbit.current.object.position.x) < epsilon &&
        Math.abs(v.y - refOrbit.current.object.position.y) < epsilon &&
        Math.abs(v.z - refOrbit.current.object.position.z) < epsilon
      );
    };

    refOrbit.current.target.equals = (v, epsilon = Number.EPSILON) => {
      return (
        Math.abs(v.x - refOrbit.current.target.x) < epsilon &&
        Math.abs(v.y - refOrbit.current.target.y) < epsilon &&
        Math.abs(v.z - refOrbit.current.target.z) < epsilon
      );
    };
  }, []);

  useEffect(() => {}, [isAnimating]);

  useFrame(() => {
    if (isAnimating) {
      if (secondaryMenuOn) {
        if (
          !refOrbit.current.object.position.equals(
            new THREE.Vector3(150, 500, -40),
            0.1
          ) &&
          !refOrbit.current.target.equals(new THREE.Vector3(130, 0, 80), 0.1)
        ) {
          refOrbit.current.object.position.lerp(
            new THREE.Vector3(150, 500, -40),
            data.animationSpeed
          );
          refOrbit.current.target.lerp(
            new THREE.Vector3(130, 0, 80),
            data.animationSpeed
          );
        } else {
          setIsAnimating(false);
        }
      } else {
        if (
          !refOrbit.current.object.position.equals(
            new THREE.Vector3(
              data.entryPos[local].camPosX,
              data.entryPos[local].camPosY,
              data.entryPos[local].camPosZ
            ),
            0.1
          )
        ) {
          // console.log("entry", local, data.entryPos[local].camPosX);
          refOrbit.current.object.position.lerp(
            new THREE.Vector3(
              data.entryPos[local].camPosX,
              data.entryPos[local].camPosY,
              data.entryPos[local].camPosZ
            ),
            data.animationSpeed
          );
        }
        if (
          !refOrbit.current.target.equals(
            new THREE.Vector3(
              data.entryPos[local].targetPosX,
              data.entryPos[local].targetPosY,
              data.entryPos[local].targetPosZ
            ),
            0.1
          )
        ) {
          refOrbit.current.target.lerp(
            new THREE.Vector3(
              data.entryPos[local].targetPosX,
              data.entryPos[local].targetPosY,
              data.entryPos[local].targetPosZ
            ),
            data.animationSpeed
          );
        }
      }
      // refOrbit.current.maxPolarAngle = lerp(
      //   refOrbit.current.maxPolarAngle,
      //   data.maxPolarAngle,
      //   data.animationSpeed
      // );
      refOrbit.current.update();
    } else {
      if (isHover) {
        // console.log("is hover", data.entryPos[local]);
        // if (location !== null) {
        //   refOrbit.current.enableRotate = true;
        //   refOrbit.current.enableZoom = true;
        //   setData((values) => ({
        //     ...values,
        //     entryPos: {
        //       ...values.entryPos,
        //       [local]: {
        //         camPosX: locationProps[location].cameraOut.pos.x,
        //         camPosY: locationProps[location].cameraOut.pos.y,
        //         camPosZ: locationProps[location].cameraOut.pos.z,
        //         targetPosX: locationProps[location].cameraOut.target.x,
        //         targetPosY: locationProps[location].cameraOut.target.y,
        //         targetPosZ: locationProps[location].cameraOut.target.z,
        //         animationSpeed: locationProps[location].animationSpeed,
        //       },
        //     },
        //   }));
        //   setIsAnimating(true);
        // }
      }
    }
  });
};

export default AnimateCamera;
